<template>
  <h3 class="title is-3">Client Summary</h3>
  <p>A placeholder for later expansion</p>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

export default {
  name: "ClientSummary",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
};
</script>
