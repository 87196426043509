import { defineStore } from "pinia";

export const useExerciseLibStore = defineStore({
  id: "ExerciseLib",
  state: () => ({
    ColorOptions: [
      { value: "#ce3b3b", name: "red" },
      { value: "#ffffff", name: "white" },
      { value: "#336699", name: "blue" },
      { value: "#3b6fce", name: "brighter blue" },
      { value: "#6fbe44", name: "green" },
      { value: "#e99b1f", name: "orange" },
      { value: "#61ce3b", name: "green" },
      { value: "#ce3bc9", name: "purple" },
      { value: "#2dbbd4", name: "turquoise" },
      { value: "#adadad", name: "grey" },
      { value: "#cecece", name: "light_grey" },
      { value: "#6a6a6a", name: "dark_grey" },
      { value: "#b9b2b0", name: "warm_grey" },
      { value: "#b0b3b9", name: "cool_grey" },
      { value: "#000000", name: "black" },
      { value: "none", name: "transparent" },
    ],
    gridSizeOptions: [
      { value: 5, name: "2 pts" },
      { value: 7, name: "3 pts" },
      { value: 9, name: "4 pts" },
    ],
    //TOP SETTINGS PANEL
    foregroundAnimTypes: [
      { name: "No Fixation Point", value: "none" },
      { name: "Single Fixation Point", value: "fixation" },
      { name: "Sequence of Fixation Points", value: "fixationsequence" },
      { name: "Saccade Testing", value: "saccadefromcenter" },
      { name: "Anti-Saccade", value: "antisaccade" },
      { name: "Point to Point Animation", value: "pointtopoint" },
      { name: "Random Saccade", value: "randomsaccade" },
    ],
    backgroundAnimTypes: [
      { name: "Color Background", value: "color" },
      { name: "Texture Background", value: "texture" },
      { name: "Optokinetic Stimulation", value: "opk" },
    ],
    //FIXATION POINT SETTINGS
    fixPointTypeOptions: [
      { name: "A square", value: "ri-input-method-fill" },
      { name: "arrows 1", value: "ri-drag-move-fill" },
      { name: "arrows 2", value: "ri-drag-move-2-fill" },
      { name: "bug", value: "ri-bug-fill" },
      { name: "coin", value: "ri-coin-fill" },
      { name: "cog", value: "ri-settings-2-fill" },
      { name: "command", value: "ri-command-fill" },
      { name: "copper coin", value: "ri-copper-coin-fill" },
      { name: "diamond", value: "ri-vip-diamond-fill" },
      { name: "dollar box", value: "ri-money-dollar-box-fill" },
      { name: "dollar circle", value: "ri-money-dollar-circle-fill" },
      { name: "drop", value: "ri-drop-fill" },
      { name: "earth", value: "ri-earth-fill" },
      { name: "emphathy", value: "ri-empathize-fill" },
      { name: "face happy", value: "ri-emotion-happy-fill" },
      { name: "face laughing", value: "ri-emotion-laugh-fill" },
      { name: "face sad", value: "ri-emotion-sad-fill" },
      { name: "face straight", value: "ri-emotion-normal-fill" },
      { name: "face unhappy", value: "ri-emotion-unhappy-fill" },
      { name: "fire", value: "ri-fire-fill" },
      { name: "focus 1", value: "ri-focus-line" },
      { name: "focus 2", value: "ri-focus-2-line" },
      { name: "focus 3", value: "ri-focus-3-line" },
      { name: "focus 4", value: "ri-focus-fill" },
      { name: "focus 5", value: "ri-focus-2-fill" },
      { name: "focus 6", value: "ri-focus-3-fill" },
      { name: "globe", value: "ri-global-line" },
      { name: "grid 1", value: "ri-layout-grid-fill" },
      { name: "grid 2", value: "ri-grid-fill" },
      { name: "heart", value: "ri-heart-fill" },
      { name: "heart outline", value: "ri-heart-line" },
      { name: "home 1", value: "ri-home-2-line" },
      { name: "home 2", value: "ri-home-2-fill" },
      { name: "life-saver", value: "ri-lifebuoy-fill" },
      { name: "magic", value: "ri-magic-fill" },
      { name: "map pin", value: "ri-map-pin-2-fill" },
      { name: "record circle", value: "ri-record-circle-line" },
      { name: "record circle2", value: "ri-record-circle-fill" },
      { name: "rocket", value: "ri-rocket-fill" },
      { name: "settings", value: "ri-settings-fill" },
      { name: "shape", value: "ri-shape-2-line" },
      { name: "stop 1", value: "ri-stop-line" },
      { name: "stop 2", value: "ri-stop-fill" },
      { name: "stop circle 1", value: "ri-stop-circle-line" },
      { name: "stop circle 2", value: "ri-stop-circle-fill" },
      { name: "star 1", value: "ri-star-fill" },
      { name: "star 2", value: "ri-star-line" },
      { name: "sun", value: "ri-sun-fill" },
      { name: "target", value: "ri-speaker-2-line" },
      { name: "virus", value: "ri-virus-fill" },
    ],
    fixPointSizeOptions: [
      { value: "ri-xxs", name: "xx-small" },
      { value: "ri-xs", name: "x-small" },
      { value: "ri-sm", name: "small" },
      { value: "ri-1x", name: "x1" },
      { value: "ri-lg", name: "large" },
      { value: "ri-xl", name: "x-large" },
      { value: "ri-2x", name: "x2" },
      { value: "ri-3x", name: "x3" },
      { value: "ri-4x", name: "x4" },
      { value: "ri-5x", name: "x5" },
    ],
    //FOREGROUND ARRAYS
    saccadeTypeOptions: [
      { value: "gap", name: "Gap" },
      { value: "overlap", name: "Overlap" },
    ],
    saccadeTargetMethodList: [
      { value: "random", name: "random" },
      { value: "cycle", name: "cycle" },
      { value: "tByt", name: "target by target" },
    ],
    pursuitSpeedOptions: [
      { value: 0.8, name: "slowest" },
      { value: 0.5, name: "slower" },
      { value: 0.3, name: "slow" },
      { value: 0.2, name: "normal" },
      { value: 0.15, name: "fast" },
      { value: 0.1, name: "faster" },
      { value: 0.05, name: "fastest" },
    ],
    saccadeIntervalOptions: [
      { value: 0.4, name: ".4 sec" },
      { value: 0.6, name: ".6 sec" },
      { value: 0.8, name: ".8 sec" },
      { value: 1, name: "1 sec" },
      { value: 2, name: "2 sec" },
      { value: 3, name: "3 sec" },
      { value: 4, name: "4 sec" },
      { value: 6, name: "6 sec" },
      { value: 8, name: "8 sec" },
      { value: 10, name: "10 sec" },
      { value: 12, name: "12 sec" },
      { value: 14, name: "14 sec" },
      { value: 16, name: "16 sec" },
      { value: 18, name: "18 sec" },
      { value: 20, name: "20 sec" },
    ],
    //BACKGROUND ARRAYS
    // HEMISTIM
    HStimDelayOptions: [
      { value: "100", name: "Very Fast (100ms)" },
      { value: "200", name: "Fast (200ms)" },
      { value: "300", name: "Medium (400ms)" },
      { value: "400", name: "Slow (600ms)" },
      { value: "500", name: "Very Slow (800ms)" },
    ],
    //OPK/OKN SETTINGS
    OPKDirectionOptions: [
      { value: "L", name: "Left" },
      { value: "R", name: "Right" },
      { value: "U", name: "Up" },
      { value: "D", name: "Down" },
      { value: "DL", name: "Down & Left" },
      { value: "DR", name: "Down & Right" },
      { value: "UL", name: "Up & Left" },
      { value: "UR", name: "Up & Right" },
    ],
    BGSizeOptions: [
      { value: "50", name: "Smallest" },
      { value: "100", name: "Very Small" },
      { value: "200", name: "Small" },
      { value: "400", name: "Medium" },
      { value: "600", name: "Large" },
      { value: "1200", name: "Very Large" },
      { value: "2000", name: "Largest" },
    ],
    OPKSpeedOptions: [
      { value: ".5", name: "Slowest" },
      { value: ".75", name: "Very Slow" },
      { value: "1", name: "Slower" },
      { value: "1.25", name: "Slow" },
      { value: "1.5", name: "Medium" },
      { value: "1.75", name: "Fast" },
      { value: "2", name: "Faster" },
      { value: "2.5", name: "Very Fast" },
      { value: "4", name: "Fastest" },
    ],
    //STILL BACKGROUND
    backTextureOptions: [
      { value: "stripes", name: "Stripes" },
      { value: "img1", name: "Image 1" },
      { value: "img2", name: "Image 2" },
      { value: "img3", name: "Image 3" },
      { value: "img4", name: "Image 4" },
      { value: "img5", name: "Image 5" },
      { value: "img6", name: "Image 6" },
      { value: "img7", name: "Image 7" },
      { value: "argyle", name: "Argyle" },
      { value: "carbon", name: "Carbon" },
      { value: "carbon_fibre", name: "Carbon Fibre" },
      { value: "squares", name: "Squares" },
      { value: "tiles", name: "Tiles" },
      { value: "half_circles", name: "Half Circles" },
      { value: "circles", name: "Circles" },
      { value: "tartan", name: "Tartan" },
      { value: "red_tartan", name: "Red Tartan" },
    ],
  }),
});
