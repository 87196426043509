<template>
  <div v-if="isLoaded" class="card events-card">
    <header class="card-header">
      <p class="card-header-title">
        {{ authStore.selectedClient.first_name }}
        {{ authStore.selectedClient.last_name }} Details
      </p>
      <!-- <p v-else class="card-header-title">Other title</p> -->
      <a href="#" class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
    </header>
    <div class="card-content">
      <BasicInput
        name="username"
        title="Username"
        type="text"
        v-model="authStore.selectedClient.username"
      >
      </BasicInput>

      <BasicInput
        name="fname"
        title="First Name"
        type="text"
        v-model="authStore.selectedClient.first_name"
      >
      </BasicInput>

      <BasicInput
        name="lname"
        title="Last Name"
        type="text"
        v-model="authStore.selectedClient.last_name"
      >
      </BasicInput>

      <BasicInput
        name="email"
        title="Email"
        type="email"
        v-model="authStore.selectedClient.email"
      >
      </BasicInput>

      <section class="section">
        <div
          v-if="showNotify"
          class="notification is-light"
          :class="authStore.notifyClass"
        >
          {{ authStore.responseMsg }}
        </div>
        <div class="field is-grouped is-justify-content-center">
          <div class="control">
            <button @click.prevent="updateClient" class="button is-link">
              Save/Update
            </button>
          </div>
          <div class="control">
            <button class="button is-link is-light">Clear</button>
          </div>
        </div>
      </section>
    </div>
    <!-- <div v-else class="card-content">
      <div class="content">No clients found</div>
    </div> -->
    <footer class="card-footer">Footer</footer>
  </div>
  <div v-else>Nothing</div>
</template>

<script>
import BasicInput from "./BasicInput.vue";

import { useAuthStore } from "@/store/Auth";

export default {
  name: "ClientDetails",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { BasicInput },
  data: () => ({
    showNotify: false,
  }),
  computed: {
    isLoaded() {
      return this.authStore.selectedClient != null ? true : false;
    },
  },
  methods: {
    updateClient() {
      this.authStore.updateUser(this.authStore.selectedClient);
      this.showNotify = true;
    },
  },
};
</script>

<style></style>
