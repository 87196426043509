<template>
  <div
    id="texture"
    :class="[AnimateTexture === 'yes' ? animateClass : '', backTexture]"
  ></div>
</template>
<!-- :class="ex.backgroundType == 'Texture' ? ex.backTexture : ''" -->

<script>
// import anime from "animejs";
// import "@/assets/css/textures.css";

export default {
  data() {
    return {
      animateClass: "animate",
    };
  },
  props: [
    "direction",
    "stripeWidth",
    "speed",
    "backTexture",
    "stripe1Color",
    "stripe2Color",
    "AnimateTexture",
  ],
  computed: {
    stripeAngle() {
      let v = "90deg";
      // check viable inputs
      if (["L", "R"].includes(this.direction)) {
        v = "90deg";
      } else if (["U", "D"].includes(this.direction)) {
        v = "0deg";
      } else if (["UL", "LU", "DR", "RD"].includes(this.direction)) {
        v = "-45deg";
      } else if (["UR", "RU", "DL", "LD"].includes(this.direction)) {
        v = "45deg";
      } else {
        //error - value not permitted
        // this.speed = 0;
        alert(
          "Error: Input value not permitted. Please contact developers with information about how you came to see this error. Thank you."
        );
      }
      return v;
    },
    stripeWidthUnits() {
      return this.stripeWidth + "px";
    },
    optoDuration() {
      const time = this.stripeWidth / (this.speed * 300);
      if (["L", "R", "U", "D"].includes(this.direction)) {
        return time + "s";
      } else if (
        ["UL", "LU", "DR", "RD", "UR", "RU", "DL", "LD"].includes(
          this.direction
        )
      ) {
        return Math.sqrt(Math.pow(time, 2) * 2) + "s";
      } else {
        return 0 + "s";
      }
    },
    translateXArray() {
      if (this.direction.includes("L")) {
        return [0, -this.stripeWidth + "px"];
      } else if (this.direction.includes("R")) {
        return [-this.stripeWidth + "px", 0];
      } else {
        return [0, 0];
      }
    },
    translateYArray() {
      if (this.direction.includes("U")) {
        return [0, -this.stripeWidth + "px"];
      } else if (this.direction.includes("D")) {
        return [-this.stripeWidth + "px", 0];
      } else {
        return [0, 0];
      }
    },
  },
  // methods: {
  //   animateBackground() {
  //     console.log("animate!!!");
  //     anime({
  //       targets: "#texture",
  //       translateX: this.translateXArray,
  //       translateY: this.translateYArray,
  //       duration: this.stripeWidth / this.speed,
  //       easing: "linear",
  //       direction: "loop",
  //       loop: true,
  //     });
  //   },
  // },
  mounted() {
    // this.animateBackground();
  },
};
</script>

<style scoped>
#texture {
  position: relative;
  height: 300%;
  width: 300%;
  margin: 0px;
  padding: 0px;
  background-size: v-bind(stripeWidthUnits) v-bind(stripeWidthUnits);
}

.animate {
  animation: move v-bind(optoDuration) linear forwards infinite;
}

@keyframes move {
  0% {
    transform: translate(
      v-bind(translateXArray[0]),
      v-bind(translateYArray[0])
    );
  }
  100% {
    transform: translate(
      v-bind(translateXArray[1]),
      v-bind(translateYArray[1])
    );
  }
}

.stripes {
  background-image: linear-gradient(
    v-bind(stripeAngle),
    v-bind(stripe1Color) 25%,
    v-bind(stripe2Color) 25%,
    v-bind(stripe2Color) 50%,
    v-bind(stripe1Color) 50%,
    v-bind(stripe1Color) 75%,
    v-bind(stripe2Color) 75%,
    v-bind(stripe2Color) 100%
  );
}
/* .stripes {
    background-image: linear-gradient( var(--stripe-angle),
        var(--stripe1-color) 25%,
        var(--stripe2-color) 25%,
        var(--stripe2-color) 50%,
        var(--stripe1-color) 50%,
        var(--stripe1-color) 75%,
        var(--stripe2-color) 75%,
        var(--stripe2-color) 100%);
} */
.img1 {
  background-image: url("@/assets/img/1036.png");
}
.img2 {
  background-image: url("@/assets/img/1039.png");
}
.img3 {
  background-image: url("@/assets/img/1050.png");
}
.img4 {
  background-image: url("@/assets/img/1053.png");
}
.img5 {
  background-image: url("@/assets/img/1056.png");
}
.img6 {
  background-image: url("@/assets/img/1061.png");
}
.img7 {
  background-image: url("@/assets/img/1063.png");
}

.carbon {
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
    linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
    linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
    linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
    linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
    linear-gradient(
      #1d1d1d 25%,
      #1a1a1a 25%,
      #1a1a1a 50%,
      transparent 50%,
      transparent 75%,
      #242424 75%,
      #242424
    );
  background-color: #131313;
  background-size: 20px 20px;
}
.carbon_fibre {
  background: radial-gradient(black 15%, transparent 16%) 0 0,
    radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
  background-color: #282828;
  background-size: 16px 16px;
}
.argyle {
  background-color: #6d695c;
  background-image: repeating-linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px,
      transparent 60px
    ),
    repeating-linear-gradient(
      60deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px,
      transparent 60px
    ),
    linear-gradient(
      60deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1)
    ),
    linear-gradient(
      120deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.1)
    );
  background-size: 70px 120px;
}
.squares {
  background: linear-gradient(
    45deg,
    #445 50%,
    transparent 50%
  ); /* On top,    like z-index: 4; */
}
.tiles {
  background-color: #00b5f7;
  background-size: 53px 53px;
  background-image: radial-gradient(
    transparent 28px,
    #ffffff 28px,
    #ffffff 32px,
    transparent 32px
  );
}
.half_circles {
  background-color: #f7a800;
  background-size: 56px 56px;
  background-image: linear-gradient(135deg, transparent 55%, #f7a800 55%),
    radial-gradient(
      transparent 6px,
      #ffffff 6px,
      #ffffff 10px,
      transparent 10px,
      transparent 15px,
      #ffffff 15px,
      #ffffff 19px,
      transparent 19px,
      transparent 24px,
      #ffffff 24px,
      #ffffff 28px,
      transparent 28px
    );
}
.tartan {
  color: white;
  background-color: #66a3ff;
  background-image: repeating-linear-gradient(
      transparent,
      transparent 50px,
      purple 50px,
      purple 53px,
      transparent 53px,
      transparent 63px,
      purple 63px,
      purple 66px,
      transparent 66px,
      transparent 116px,
      rgba(0, 0, 0, 0.5) 116px,
      rgba(0, 0, 0, 0.5) 166px,
      rgba(255, 255, 255, 0.2) 166px,
      rgba(255, 255, 255, 0.2) 169px,
      rgba(0, 0, 0, 0.5) 169px,
      rgba(0, 0, 0, 0.5) 179px,
      rgba(255, 255, 255, 0.2) 179px,
      rgba(255, 255, 255, 0.2) 182px,
      rgba(0, 0, 0, 0.5) 182px,
      rgba(0, 0, 0, 0.5) 232px,
      transparent 232px
    ),
    repeating-linear-gradient(
      270deg,
      transparent,
      transparent 50px,
      purple 50px,
      purple 53px,
      transparent 53px,
      transparent 63px,
      purple 63px,
      purple 66px,
      transparent 66px,
      transparent 116px,
      rgba(0, 0, 0, 0.5) 116px,
      rgba(0, 0, 0, 0.5) 166px,
      rgba(255, 255, 255, 0.2) 166px,
      rgba(255, 255, 255, 0.2) 169px,
      rgba(0, 0, 0, 0.5) 169px,
      rgba(0, 0, 0, 0.5) 179px,
      rgba(255, 255, 255, 0.2) 179px,
      rgba(255, 255, 255, 0.2) 182px,
      rgba(0, 0, 0, 0.5) 182px,
      rgba(0, 0, 0, 0.5) 232px,
      transparent 232px
    );
}

.red_tartan {
  background-color: #d50000;
  background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8) 100px,
      transparent 0px,
      transparent 200px
    ),
    repeating-linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5) 100px,
      transparent 0px,
      transparent 200px
    );
}
</style>
