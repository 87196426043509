<template>
  <div class="columns p-4">
    <div class="column is-2">
      <ButtonComp type="add" @clickEvent="addPreset()"> Add Preset </ButtonComp>
    </div>
    <div class="column">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Preset List:</p>
          <a href="#" class="card-header-icon" aria-label="more options"> </a>
        </header>
        <div class="card-content">
          <div class="content">
            <table class="table is-fullwidth is-striped is-hoverable">
              <tbody>
                <tr
                  v-for="(item, index) in authStore.presetList"
                  :key="item.id"
                >
                  <td>
                    <div class="field is-grouped has-addons is-centered">
                      <ButtonComp
                        :disabled="index < 1"
                        type="up"
                        @clickEvent="swapUp(item.order)"
                      >
                      </ButtonComp>
                      <ButtonComp
                        :disabled="index > authStore.presetList.length - 2"
                        type="down"
                        @clickEvent="swapDown(item.order)"
                      >
                      </ButtonComp>
                    </div>
                  </td>
                  <td>
                    {{ item.order }}
                  </td>
                  <td>
                    {{ item.title }}
                  </td>
                  <td class="level-right">
                    <div class="field is-grouped">
                      <ButtonComp
                        type="delete"
                        @clickEvent="confirmDelete(index)"
                      >
                        Delete
                      </ButtonComp>
                      <ButtonComp type="edit" @clickEvent="editPreset(index)">
                        Edit
                      </ButtonComp>
                      <ButtonComp type="play" @clickEvent="playPreset(index)">
                        Preview
                      </ButtonComp>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div v-else class="card-content">
          <div class="content">No Presets</div>
        </div> -->
        <!-- <footer class="card-footer">
      <a href="#" class="card-footer-item">View All</a>
    </footer> -->
      </div>
    </div>
  </div>

  <div>
    <!-- MODALS -->

    <!-- MODAL FOR ADDING EXERCISE -->
    <ModalComp
      @closeModal="closeExModal()"
      title="Add Exercise From Preset"
      :openModal="addExModal"
    >
      <ExercisePresets :PresetEdit="true" />
    </ModalComp>

    <!-- MODAL FOR DELETING EXERCISE -->
    <ModalComp
      @closeModal="closeDeleteModal()"
      title="Confirm Delete"
      :openModal="deleteModal"
    >
      <div class="container">
        <p class="is-size-4">Delete this preset?</p>
        <article
          v-if="authStore.presetSelection != undefined"
          class="message is-link"
        >
          <div class="message-body">
            <p class="is-size-5">
              <span class="has-text-weight-light">
                <i class="ri-health-book-fill is-size-3"></i>
                {{ authStore.selectedPreset.title }}</span
              >
            </p>
          </div>
        </article>

        <hr />
        <article class="message is-danger">
          <div class="message-body">
            CAN NOT BE UNDONE. ALL DATA WILL BE LOST!
          </div>
        </article>
        <div class="field is-grouped justify-content-center">
          <ButtonComp
            type="delete"
            @clickEvent="deletePreset(authStore.selectedPreset.id)"
          >
            Delete Preset
          </ButtonComp>
          <ButtonComp type="cancel" @clickEvent="closeDeleteModal()">
            Cancel
          </ButtonComp>
        </div>
      </div>
    </ModalComp>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

// import VizStimSettings from "@/components/VizStimSettings.vue";
import ExercisePresets from "./ExercisePresets.vue";
import ButtonComp from "../dashboard_components/ButtonComp.vue";
import ModalComp from "../dashboard_components/ModalComp.vue";

export default {
  name: "PresetList",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { ExercisePresets, ButtonComp, ModalComp },
  data: () => ({
    addExModal: false,
    deleteModal: false,
    exerciseID: null,
  }),
  computed: {
    isLoaded() {
      var check = false;
      if (this.authStore.selectedClient !== null) {
        if (
          this.authStore.selectedClient.prescriptions[0].exercises !==
            undefined &&
          this.authStore.selectedClient.prescriptions[0].exercises.length > 0
        ) {
          check = true;
        }
      }
      return check;
    },
  },
  methods: {
    editPreset(index) {
      this.authStore.presetSelection = index;
      this.$router.push({ name: "edit-preset" });
    },
    deletePreset(id) {
      this.authStore.deletePreset(id);
      this.closeDeleteModal();
    },
    closeExModal() {
      this.addExModal = false;
    },
    addPreset() {
      this.addExModal = true;
    },
    confirmDelete(index) {
      this.authStore.presetSelection = index;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.authStore.presetSelection = null;
      this.deleteModal = false;
    },
    playPreset(index) {
      this.authStore.presetSelection = index;
      //   this.authStore.selectedPreset.data = this.exStore.data;
      this.$router.push({
        name: "player",
      });
    },
    swapDown(index) {
      this.authStore.swapPresets(index - 1, index);
    },
    swapUp(index) {
      this.authStore.swapPresets(index - 2, index - 1);
    },
  },
  mounted() {
    this.authStore.getPresetList();
  },
  //   data: () => ({

  //   }),
};
</script>

<style></style>
