import { createRouter, createWebHistory } from "vue-router";

import { useAuthStore } from "@/store/Auth";
// import { LoginView } from "@/views";
import ClientSummary from "@/components/provider_dash_components/ClientSummary.vue";
import ClientList from "@/components/provider_dash_components/ClientList.vue";
import ClientDetails from "@/components/provider_dash_components/ClientDetails.vue";
import EditClient from "@/components/provider_dash_components/EditClient.vue";
import ExerciseList from "@/components/provider_dash_components/ExerciseList.vue";
import ProviderDetails from "@/components/provider_dash_components/ProviderDetails.vue";
import EditPrescription from "@/components/provider_dash_components/EditPrescription.vue";
import EditExercise from "@/components/EditExercise.vue";
import ExerciseView from "@/components/provider_dash_components/ExerciseView.vue";
import PresetList from "@/components/provider_dash_components/PresetList.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "login",
    props: (route) => ({ query: route.query.q }),
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/client",
    redirect: { name: "overview" },
    name: "clientdash",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ClientDashboardView.vue"
      ),
    children: [
      {
        path: "overview",
        name: "overview",
      },
      {
        path: "resources",
        name: "resources",
      },
      {
        path: "education",
        name: "education",
      },
    ],
  },
  {
    path: "/provider",
    redirect: { name: "clients" },
    name: "provider",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProviderDashView.vue"),
    children: [
      {
        path: "clients",
        name: "clients",
        component: ClientList,
        props: true,
      },
      {
        path: "profile",
        name: "profile",
        component: ProviderDetails,
      },
      {
        path: "presets",
        name: "presets",
        redirect: { name: "preset-list" },
        component: ExerciseView,
        children: [
          {
            path: "preset-list",
            name: "preset-list",
            component: PresetList,
          },
          {
            path: "edit-preset",
            name: "edit-preset",
            component: EditExercise,
            props: { PresetEdit: true },
          },
        ],
      },
      {
        path: "client",
        name: "client",
        // alias: "edit-client",
        component: EditClient,
        children: [
          {
            path: "",
            name: "edit-client",
            component: ClientSummary,
          },
          {
            path: "edit-prescript",
            name: "edit-prescript",
            component: EditPrescription,
          },
          {
            path: "exercises",
            name: "exercises",
            component: ExerciseView,
            children: [
              {
                path: "",
                name: "exercise-list",
                component: ExerciseList,
              },
              {
                path: "edit-exercise",
                name: "edit-exercise",
                component: EditExercise,
                props: { PresetEdit: false },
              },
            ],
          },
          {
            path: "client-details",
            name: "client-details",
            component: ClientDetails,
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
  },
  {
    path: "/player",
    name: "player",
    // props: true,
    props: (route) => ({
      ...route.params,
    }),
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PlayView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  // const publicPages = ["login", "home"];
  // const authRequired = !publicPages.includes(to.name);
  const auth = useAuthStore();

  await auth.checkUser();
  // if (authRequired && !auth.isAuthenticated) {
  //   auth.returnUrl = to.fullPath;
  //   return { name: "home" };
  // }
  if (to.name === "dashboard") {
    if (auth.user.role === "PROVIDER") {
      return { name: "provider" };
    } else if (auth.user.role === "CLIENT") {
      return { name: "clientdash" };
    }
  }
});

export default router;
