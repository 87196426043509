<template>
  <div v-if="authStore.clientSelection == null" class="container">
    <div class="container">
      <ButtonComp type="add" @clickEvent="openModal = true">
        NEW CLIENT
      </ButtonComp>
    </div>
    <ModalComp
      @closeModal="openModal = false"
      title="Create New Client"
      :openModal="openModal"
    >
      <client-create />
    </ModalComp>
  </div>
  <div class="card events-card">
    <header class="card-header">
      <p class="card-header-title">Client List choice:</p>
      <a href="#" class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
    </header>
    <div class="modal" :class="{ 'is-active': deleteModal }">
      <div class="modal-background" @click.prevent="closeDeleteModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head has-background-danger">
          <p class="modal-card-title has-text-white">Confirm Delete</p>
          <button
            @click.prevent="closeDeleteModal()"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="container has-text-danger">
            <article class="message is-dark">
              <div class="message-body">
                <p class="is-size-4">CLIENT:</p>
                <p class="is-size-5">
                  <span class="has-text-weight-light">Username:</span>
                  {{ selectedClient.username }}
                </p>
                <p class="is-size-5">
                  <span class="has-text-weight-light">Name:</span>
                  {{ selectedClient.first_name }} {{ selectedClient.last_name }}
                </p>
              </div>
            </article>

            <!-- <h4 class="title is-4">
              CAN NOT BE UNDONE. ALL DATA WILL BE LOST!
            </h4> -->
            <hr />
            <article class="message is-danger">
              <div class="message-body">
                CAN NOT BE UNDONE. ALL DATA WILL BE LOST!
              </div>
            </article>

            <button
              class="button is-danger"
              @click.prevent="deleteClient(clientSel)"
            >
              Delete Client
            </button>
            <button class="button" @click.prevent="closeDeleteModal()">
              Cancel
            </button>
          </div>
        </section>
        <!-- <footer class="modal-card-foot has-background-danger"></footer> -->
      </div>
    </div>
    <div class="card-table">
      <div class="content">
        <table
          v-if="
            authStore.clientList !== null && authStore.clientList.length > 0
          "
          class="table is-fullwidth is-striped is-hoverable"
        >
          <tbody>
            <tr
              v-for="(item, index) in authStore.clientList"
              :key="item.username"
            >
              <td width="5%"><i class="fa fa-bell-o"></i></td>
              <td>
                {{ item.username }}: {{ item.last_name }},
                {{ item.first_name }} .. {{ index }}
              </td>
              <td class="level-right">
                <div class="field is-grouped">
                  <ButtonComp type="delete" @clickEvent="confirmDelete(index)">
                    Delete
                  </ButtonComp>
                  <ButtonComp type="edit" @clickEvent="editClient(index)">
                    Edit
                  </ButtonComp>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <footer class="card-footer">
      <a href="#" class="card-footer-item">View All</a>
    </footer>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

import ButtonComp from "../dashboard_components/ButtonComp.vue";
import ModalComp from "../dashboard_components/ModalComp.vue";
import ClientCreate from "./ClientCreate.vue";

export default {
  name: "ClientList",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { ButtonComp, ModalComp, ClientCreate },
  data: () => ({
    deleteModal: false,
    clientSel: null,
    openModal: false,
  }),
  computed: {
    selectedClient() {
      if (this.clientSel != null) {
        return this.authStore.clientList[this.clientSel];
      } else {
        return {
          first_name: "error",
          last_name: "error",
          username: "error",
        };
      }
    },
  },
  methods: {
    editClient(index) {
      this.authStore.clientSelection = index;
      this.$router.push({ name: "edit-client" });
    },
    confirmDelete(index) {
      this.clientSel = index;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.clientSel = null;
      this.deleteModal = false;
    },
    setClient(index) {
      this.authStore.clientSelection = index;
    },
    deleteClient(index) {
      this.authStore.clientSelection = index;
      this.authStore.deleteClient();
    },
  },
  mounted() {
    this.authStore.clientSelection = null;
  },
  //   data: () => ({

  //   }),
};
</script>

<style></style>
