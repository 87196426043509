<template>
  <div v-if="authStore.clientSelection !== null" class="container">
    <h1 class="title has-text-left has-text-grey">
      <i class="ri-folder-user-line is-4"></i>
      {{ authStore.selectedClient.last_name }},
      {{ authStore.selectedClient.first_name }}
    </h1>
  </div>
  <!-- <TabsMenu /> -->
  <router-view></router-view>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

// import TabsMenu from "../dashboard_components/TabsMenu.vue";

export default {
  name: "EditClient",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  beforeCreate() {
    if (this.authStore.selectedClient == undefined) {
      this.$router.push({ name: "clients" });
    }
  },
  mounted() {
    this.authStore.getUserExerciseList();
  },
  //   components: { TabsMenu },
};
</script>
