<template>
  <div>
    <p class="label">{{ title }}</p>
    <label class="switch">
      <input
        type="checkbox"
        v-model="selection"
        :true-value="value1"
        :false-value="value2"
      />>
      <div class="slider round"></div>
    </label>
    <p class="inactive">
      <span
        :class="{
          active: selection == value2,
          inactive: selection == value1,
        }"
        >{{ value2 }}</span
      >
      |
      <span
        :class="{
          active: selection == value1,
          inactive: selection == value2,
        }"
        >{{ value1 }}</span
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "FormToggle",
  props: {
    name: String,
    title: String,
    value1: String,
    value2: String,
    modelValue: String,
  },
  emits: ["update:modelValue"],
  computed: {
    selection: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>

<style scoped>
.active {
  font-weight: 700;
}

.inactive {
  font-weight: 300;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #101010;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #101010;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
