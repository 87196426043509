import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import "./registerServiceWorker";
import "./assets/sass/main.scss";

axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

// axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "/api";

const pinia = createPinia();
const app = createApp(App);
// app.config.globalProperties.$http = axios;

app.use(router);
app.use(pinia);
app.mount("#app");
