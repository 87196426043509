<template>
  <div class="modal" :class="{ 'is-active': openModal }">
    <div class="modal-background" @click="$emit('closeModal')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button
          @click="$emit('closeModal')"
          class="delete"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="container">
          <slot></slot>
        </div>
      </section>
      <!-- <footer class="modal-card-foot has-background-danger"></footer> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComp",
  props: {
    openModal: Boolean,
    title: String,
  },
  emits: ["closeModal"],
};
</script>

<style></style>
