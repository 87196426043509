<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Prescription:</p>
      <a href="#" class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
    </header>

    <div v-if="isLoaded" class="card-content">
      <div class="content">
        <div>
          <div class="field">
            <!-- <label class="label">Prescription/Recipe:</label> -->
            <div class="control">
              <editor
                v-model="authStore.selectedClient.prescriptions[0].description"
              />
            </div>
            <section class="section">
              <div
                v-if="showNotify"
                class="notification is-light"
                :class="authStore.notifyClass"
              >
                {{ authStore.responseMsg }}
              </div>
              <div class="field is-grouped is-justify-content-center">
                <div class="control">
                  <button
                    @click.prevent="updatePrescript"
                    class="button is-link"
                  >
                    Save/Update
                  </button>
                </div>
                <div class="control">
                  <button class="button is-link is-light">Clear</button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-content">
      <div class="content has-text-centered">
        <div class="title is-4 is-capitalized">No prescriptions.</div>
        <!-- <button
          v-for="item in authStore.prescriptOptions"
          :key="item.order"
          class="button is-success"
          @click="createPrescription"
        >
          ADD PRESCRIPTION
          <p>{{ item.title }}</p>
        </button> -->
        <PresetSelect />
      </div>
    </div>

    <footer class="card-footer">
      <a href="#" class="card-footer-item">View All</a>
    </footer>
  </div>
</template>

<script>
import PresetSelect from "./PresetSelect.vue";
// import Editor from "@tinymce/tinymce-vue";
import Editor from "@/components/tiptap/TipTap.vue";
import { useAuthStore } from "@/store/Auth";

export default {
  name: "EditPrescription",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { Editor, PresetSelect },
  data: () => ({
    // p: {
    //   client: null,
    //   description: "<h2>Your Recipe</h2>",
    //   expiry_date: null,
    //   exercises: [],
    // },
    showNotify: false,
  }),
  computed: {
    isLoaded() {
      var check = false;

      if (this.authStore.selectedClient != null) {
        const client = this.authStore.selectedClient;
        if (
          client.prescriptions != undefined &&
          client.prescriptions[0] != undefined
          // client.prescriptions[0].exercises !== undefined
        ) {
          check = true;
        }
      }
      return check;
    },
  },
  methods: {
    // editExercise(index) {
    //   this.authStore.exerciseSelection = index;
    // },
    // async createPrescription() {
    //   this.authStore.createPrescription(this.p);
    // },
    async updatePrescript() {
      try {
        this.authStore.updatePrescription(
          this.authStore.selectedClient.prescriptions[0]
        );
      } finally {
        this.showNotify = true;
      }
    },
  },
  mounted() {
    if (this.authStore.selectedClient == null) {
      this.$router.push({ name: "dashboard" });
    }
    // this.p.client = this.authStore.selectedClient.id;
    // if (
    //   this.authStore.prescriptions != undefined &&
    //   this.authStore.prescriptions.length > 0
    // ) {
    //   this.p = this.authStore.prescriptions[0];
    // }
  },
};
</script>

<style></style>
