<template>
  <div>
    <div class="block" v-if="type == 'pursuit'">
      <Options
        name="pursuitSpeed"
        title="Smooth Pursuit Speed:"
        v-model="purSpeed"
        :array="pursuitsOptions"
      />
    </div>
    <div class="block" v-if="type == 'saccade'">
      <Options
        name="SaccadeSteps"
        title="Number of Saccades:"
        v-model="sacSteps"
        :array="saccadeOptions"
      />
      <Options
        name="saccadeType"
        title="Saccade Type:"
        v-model="sacType"
        :array="saccadeTypeOptions"
      />
      <RangeInput
        title="Inter-saccadic pause:"
        nameFrom="saccades"
        nameTo="randSaccPauseTo"
        v-model:from="saccadeInt[0]"
        v-model:to="saccadeInt[1]"
        :min="0.5"
        :max="15"
        :step="0.5"
      />
    </div>
  </div>
</template>

<script>
import Options from "./Options.vue";
import RangeInput from "./RangeInput.vue";

export default {
  props: [
    "type",
    "title",
    "pursuitSpeed",
    "saccadeSteps",
    "saccadeType",
    "saccadeInterval",
    "saccadeOptions",
    "saccadeTypeOptions",
    "pursuitsOptions",
  ],
  components: { Options, RangeInput },
  emits: [
    "update:pursuitSpeed",
    "update:saccadeSteps",
    "update:saccadeInterval",
  ],
  data() {
    return {
      saccadicInterval: [0.5, 4],
    };
  },
  computed: {
    purSpeed: {
      get() {
        return this.pursuitSpeed;
      },
      set(v) {
        this.$emit("update:pursuitSpeed", v);
      },
    },
    sacSteps: {
      get() {
        return this.saccadeSteps;
      },
      set(v) {
        this.$emit("update:saccadeSteps", v);
      },
    },
    sacType: {
      get() {
        return this.saccadeType;
      },
      set(v) {
        this.$emit("update:saccadeType", v);
      },
    },
    saccadeInt: {
      get() {
        return this.saccadeInterval;
      },
      set(v) {
        this.$emit("update:saccadeInterval", v);
      },
    },
  },
};
</script>

<style></style>
