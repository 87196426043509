<template>
  <label class="label" :for="name">{{ title }} </label>
  <div class="field has-addons has-addons-centered">
    <p class="control" v-if="name.toLowerCase().includes('color')">
      <span class="colorpot" :style="{ background: selection }"></span>
    </p>
    <p class="control">
      <span class="select">
        <select :id="name" :name="name" v-model="selection">
          <option
            v-for="(item, index) in array"
            :value="item.value"
            :key="index"
          >
            {{ item.name }}
          </option>
        </select>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "FormOptions",
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
    },
    name: String,
    title: String,
    array: Array,
  },
  emits: ["update:modelValue"],
  computed: {
    selection: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>

<style scoped>
.colorpot {
  display: inline-block;
  background: #444;
  font-size: 1.9em;
  margin-left: 0px;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid #bbb;
  border-radius: 5px 0px 0px 5px;
}
</style>
