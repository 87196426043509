<template>
  <label v-if="title != undefined" class="label">{{ title }} </label>
  <div class="block">
    <div>
      <input
        type="number"
        class="input narrowinput"
        id="from"
        :name="nameFrom"
        v-model.number="fromNum"
        :min="min"
        :max="toNum"
        :step="step"
      />
      -
      <input
        type="number"
        class="input narrowinput"
        id="to"
        :name="nameTo"
        v-model.number="toNum"
        :min="fromNum"
        :max="max"
        :step="step"
      />
    </div>

    <label>range is from {{ min }} to {{ max }} secs</label>
  </div>
</template>

<script>
export default {
  props: ["title", "nameFrom", "nameTo", "from", "to", "min", "max", "step"],
  emits: ["update:from", "update:to"],
  computed: {
    fromNum: {
      get() {
        return this.from;
      },
      set(v) {
        this.$emit("update:from", v);
      },
    },
    toNum: {
      get() {
        return this.to;
      },
      set(v) {
        this.$emit("update:to", v);
      },
    },
  },
};
</script>

<style scoped>
.override {
  margin: 0;
}

.input {
  width: 6em !important;
}
</style>
