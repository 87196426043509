<template>
  <!-- <section class="info-tiles">
    <div class="columns is-multiline has-text-centered">
      <div
        v-for="item in authStore.presetList"
        :key="item.order"
        class="column is-4"
      >
        <article @click="createExercise(item)" class="is-primary box">
          <p class="title is-5">{{ item.title }}</p> -->
  <!-- <p class="subtitle">{{ item.subtitle }}</p> -->
  <!-- <button class="button is-primary">Add</button> -->
  <!-- </article>
      </div>
    </div>
  </section> -->
  <table class="table is-fullwidth is-striped is-hoverable">
    <tbody>
      <tr v-for="item in authStore.presetList" :key="item.order">
        <td width="5%"><i class="fa fa-bell-o"></i></td>
        <td>
          {{ item.title }}
        </td>
        <td class="level-right">
          <button
            class="button is-small is-success"
            @click.prevent="addExercise(item)"
          >
            <span class="icon">
              <i class="ri-add-circle-fill"></i>
            </span>
            <span>Add</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

export default {
  name: "ExercisePresets",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  props: {
    PresetEdit: Boolean,
  },
  methods: {
    addExercise(item) {
      if (this.PresetEdit == undefined || this.PresetEdit == false) {
        //add the associated prescription to object to send
        if (
          this.authStore.selectedClient.prescriptions != undefined &&
          this.authStore.selectedClient.prescriptions.length > 0
        ) {
          if (
            this.authStore.selectedClient.prescriptions[0].exercises ==
            undefined
          ) {
            this.authStore.selectedClient.prescriptions[0].exercises = [];
          }
          if (
            this.authStore.selectedClient.prescriptions[0].exercises.length < 1
          ) {
            item.list_id = 1;
          } else {
            const exlist =
              this.authStore.selectedClient.prescriptions[0].exercises;
            item.list_id = exlist[exlist.length - 1].list_id + 1;
          }
          item.prescription =
            this.authStore.clientList[
              this.authStore.clientSelection
            ].prescriptions[0].id;
          //send object to create exercise
          this.authStore.createExercise(item);
        }
      } else {
        const presetList = this.authStore.presetList;
        item.order = presetList[presetList.length - 1].order + 1;
        this.authStore.createPreset(item);
        // console.log("I am in Preset e", this.PresetEdit);
      }
    },
  },
  mounted() {
    this.authStore.getPresetList();
  },
};
</script>

<style>
article:hover {
  cursor: pointer;
  background: #ddd;
}
</style>
