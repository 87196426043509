<template>
  <div class="card events-card">
    <header class="card-header">
      <p v-if="loaded" class="card-header-title">
        {{ authStore.user.first_name }}
        {{ authStore.user.last_name }} Details
      </p>
      <a href="#" class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
    </header>
    <div v-if="loaded" class="card-content">
      <BasicInput
        name="username"
        title="Username"
        type="text"
        v-model="authStore.user.username"
      >
      </BasicInput>

      <BasicInput
        name="fname"
        title="First Name"
        type="text"
        v-model="authStore.user.first_name"
      >
      </BasicInput>

      <BasicInput
        name="lname"
        title="Last Name"
        type="text"
        v-model="authStore.user.last_name"
      >
      </BasicInput>

      <BasicInput
        name="email"
        title="Email"
        type="email"
        v-model="authStore.user.email"
      >
      </BasicInput>

      <div class="field">
        <section class="section">
          <div
            v-if="showNotify"
            class="notification is-light"
            :class="authStore.notifyClass"
          >
            {{ authStore.responseMsg }}
          </div>
          <div class="field is-grouped is-justify-content-center">
            <div class="control">
              <button @click="updateUser" class="button is-link">
                Save/Update
              </button>
            </div>
            <div class="control">
              <button class="button is-link is-light">Clear</button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- <div v-else>
      <p>No clients found</p>
    </div> -->
    <footer class="card-footer">Footer</footer>
  </div>
</template>

<script>
// import Editor from "@tinymce/tinymce-vue";
import BasicInput from "./BasicInput.vue";

import { useAuthStore } from "@/store/Auth";

export default {
  name: "ClientDetails",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { BasicInput },
  data: () => ({
    showNotify: false,
  }),
  methods: {
    loaded() {
      return this.authStore.clientList[this.index] !== undefined &&
        this.authStore.clientList.length > 0
        ? true
        : false;
    },
    updateUser() {
      this.authStore.updateUser(this.authStore.user);
      this.showNotify = true;
    },
  },
};
</script>

<style></style>
