<template>
  <div class="card-content">
    <BasicInput
      name="fname"
      title="First Name"
      type="text"
      v-model="user.first_name"
    >
    </BasicInput>

    <BasicInput
      name="lname"
      title="Last Name"
      type="text"
      v-model="user.last_name"
    >
    </BasicInput>

    <hr />

    <BasicInput
      name="username"
      title="Username"
      type="text"
      v-model="user.username"
    >
    </BasicInput>

    <BasicInput
      name="password1"
      title="Password"
      type="password"
      v-model="user.password"
    >
    </BasicInput>

    <BasicInput name="email" title="Email" type="email" v-model="user.email">
    </BasicInput>

    <BasicInput
      name="role"
      title="Role"
      type="text"
      :disabled="true"
      v-model="user.role"
    >
    </BasicInput>

    <BasicInput
      v-if="user.clinic != null"
      name="clinic"
      title="Clinic"
      type="text"
      :disabled="true"
      v-model="user.clinic.name"
    >
    </BasicInput>

    <section class="section">
      <div
        v-if="showNotify"
        class="notification is-light"
        :class="authStore.notifyClass"
      >
        {{ authStore.responseMsg }}
      </div>
      <div class="field is-grouped is-justify-content-center">
        <div class="control">
          <button @click.prevent="createClient" class="button is-link">
            Save/Update
          </button>
        </div>
        <div class="control">
          <button class="button is-link is-light">Clear</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BasicInput from "./BasicInput.vue";

import { useAuthStore } from "@/store/Auth";

export default {
  name: "ClientCreate",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { BasicInput },
  data: () => ({
    showNotify: false,
    user: {
      first_name: "",
      last_name: "",
      username: "",
      password: "",
      email: "",
      role: "CLIENT",
      clinic: null,
      //   clinic_id: null,
    },
  }),
  computed: {
    clinic() {
      return this.authStore.user.clinic.name;
    },
    isLoaded() {
      return this.authStore.clientSelection != null ? true : false;
    },
  },
  methods: {
    createClient() {
      this.authStore.createUser(this.user);
      this.showNotify = true;
    },
  },
  mounted() {
    this.user.clinic = this.authStore.user.clinic;
    // this.user.clinic_id = this.authStore.user.clinic.id;
  },
};
</script>

<style></style>
