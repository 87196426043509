<template>
  <div class="field is-horizontal">
    <div class="field-label">
      <label class="label">{{ title }}</label>
    </div>
    <div class="field-body">
      <div class="control">
        <input
          v-model="selection"
          class="input"
          :type="type"
          placeholder="Text input"
          :name="name"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicInput",
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
    },
    name: String,
    title: String,
    type: String,
    disabled: Boolean,
  },
  emits: ["update:modelValue"],
  computed: {
    selection: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>
