<template>
  <div class="container mt-4">
    <div class="card">
      <header class="card-header has-background-grey-dark">
        <p class="card-header-title has-text-white">Edit Exercise Settings</p>
        <button
          @click="closeExEdit()"
          class="card-header-icon has-text-white"
          aria-label="more options"
        >
          <span class="icon">
            <i class="ri-close-line"></i>
          </span>
          CLOSE
        </button>
      </header>
      <div
        class="card-content"
        :class="{
          'has-background-grey': !this.PresetEdit,
          'has-background-info-dark': this.PresetEdit,
        }"
      >
        <div class="container box has-background-grey-lighter">
          <div class="field">
            <label class="label">Title</label>

            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    class="input textinput"
                    id="title"
                    type="text"
                    v-model="exStore.title"
                    placeholder="Text input"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <editor v-model="exStore.instructions" />
            <!-- <Editor
        v-model="authStore.selectedExercise.instructions"
        api-key="no-api-key"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
        }"
      /> -->
          </div>
        </div>

        <div class="container box has-background-grey-light">
          <!-- FOREGROUND PANEL -->

          <div class="containter box has-background-grey-lighter">
            <div class="columns">
              <div class="column">
                <Options
                  name="foregroundAnim"
                  title="Foreground Animation Type"
                  v-model="exStore.data.foregroundAnim"
                  :array="exLibStore.foregroundAnimTypes"
                ></Options>
              </div>
              <div
                class="column"
                v-if="
                  !['randomsaccade', 'none'].includes(
                    exStore.data.foregroundAnim
                  )
                "
              >
                <Options
                  name="gridSize"
                  title="Size of Grid"
                  v-model="exStore.data.fg.gridSize"
                  :array="exLibStore.gridSizeOptions"
                />
              </div>
            </div>

            <div v-if="exStore.data.foregroundAnim === 'none'">
              <p class="label">No Foreground</p>
            </div>
            <div v-else>
              <!-- FOREGROUND ANIMATION: Animation Settings -->
              <div class="containter box has-background-grey-lighter">
                <div class="title is-5">
                  {{
                    exLibStore.foregroundAnimTypes.find(
                      (i) => i.value === exStore.data.foregroundAnim
                    ).name
                  }}
                </div>

                <!-- single fixation point -->
                <template v-if="exStore.data.foregroundAnim === 'fixation'">
                  <div class="columns">
                    <div class="column">
                      <SelectPosition
                        title="Fixation Point Location:"
                        type="radio"
                        :gridSize="exStore.data.fg.gridSize"
                        v-model="exStore.data.fg.singlePoint"
                      />
                    </div>
                  </div>
                </template>

                <!-- random saccade -->
                <template
                  v-if="exStore.data.foregroundAnim === 'randomsaccade'"
                >
                  <div class="columns">
                    <div class="column">
                      <SelectQuadrant
                        name="randSaccQuadObj"
                        v-model:inputSelect="exStore.data.fg.randSaccQuadArray"
                        v-model:inputAmount="exStore.data.fg.randSaccNumArray"
                      />
                    </div>
                    <div class="column">
                      <Toggle
                        name="tapTarget"
                        title="Progression Mode:"
                        value1="tap"
                        value2="auto"
                        v-model="exStore.data.fg.randSaccUseTapTarget"
                      />
                      <RangeInput
                        v-if="exStore.data.fg.randSaccUseTapTarget == 'auto'"
                        title="Inter-saccadic pause:"
                        nameFrom="randSaccPauseFrom"
                        nameTo="randSaccPauseTo"
                        v-model:from="exStore.data.fg.randSaccPauseInterval[0]"
                        v-model:to="exStore.data.fg.randSaccPauseInterval[1]"
                        :min="0.5"
                        :max="20"
                        :step="0.5"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <Toggle
                        name="useIcon"
                        title="Use Icon or Text:"
                        value1="text"
                        value2="icon"
                        v-model="exStore.data.fg.randSaccUseIcon"
                      />
                      <div
                        class="field"
                        v-if="exStore.data.fg.randSaccUseIcon == 'text'"
                      >
                        <label class="label">Text Sequence</label>
                        <div class="field-body">
                          <div class="field">
                            <div class="control">
                              <input
                                class="input textinput"
                                id="title"
                                type="text"
                                v-model="exStore.data.fg.randSaccText"
                                placeholder="Text input"
                              />
                            </div>
                          </div>
                        </div>
                        <div>Text Output: {{ randSaccTextOutput }}</div>
                      </div>
                    </div>
                  </div>
                </template>

                <!-- fixation point sequence -->
                <template
                  v-if="exStore.data.foregroundAnim === 'fixationsequence'"
                >
                  <div class="columns">
                    <div class="column">
                      <SelectPosition
                        title="Target Sequence"
                        name="target sequence"
                        type="checkbox"
                        :gridSize="exStore.data.fg.gridSize"
                        v-model="exStore.data.fg.fixSequencePoints"
                      />
                    </div>
                    <div class="column">
                      <SequenceNumbers
                        title="Fixation Sequence Edit:"
                        name="saccadeCount"
                        defaultNum="3"
                        :pointsSelect="exStore.data.fg.fixSequencePoints"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <Toggle
                        name="fixMethod"
                        title="Transition between points:"
                        value1="Pursuit"
                        value2="Saccade"
                        v-model="exStore.data.fg.fixMethod"
                      />
                    </div>
                    <div class="column">
                      <RangeInput
                        title="Time internal to hold fixations:"
                        nameFrom="fixSequenceIntervalFrom"
                        nameTo="fixSequenceIntervalTo"
                        v-model:from="exStore.data.fg.fixSequenceInterval[0]"
                        v-model:to="exStore.data.fg.fixSequenceInterval[1]"
                        :min="0.2"
                        :max="15"
                        :step="0.2"
                      />
                    </div>
                  </div>
                </template>

                <!-- Saccade Testing/Anti-saccade aka  -->
                <template
                  v-if="
                    exStore.data.foregroundAnim === 'saccadefromcenter' ||
                    exStore.data.foregroundAnim === 'antisaccade'
                  "
                >
                  <div class="columns">
                    <div class="column">
                      <SelectPosition
                        :title="
                          exStore.data.foregroundAnim == 'saccadefromcenter'
                            ? 'Saccade Testing:'
                            : 'Anti-Saccade:'
                        "
                        :name="
                          exStore.data.foregroundAnim == 'saccadefromcenter'
                            ? 'saccadeFromCenter'
                            : 'antiSaccade'
                        "
                        type="checkbox"
                        :gridSize="exStore.data.fg.gridSize"
                        v-model="exStore.data.fg.saccadeFromCenter"
                        disable="0"
                      />
                      <!-- pass in "disable" prop to disable any point -->
                    </div>
                    <div class="column">
                      <Options
                        name="targetMethod"
                        title="Saccade to target method:"
                        v-model="exStore.data.fg.saccadeTargetMethod"
                        :array="exLibStore.saccadeTargetMethodList"
                      />
                      <RangeInput
                        title="Start Delay:"
                        nameFrom="AntiSaccInitDelayFrom"
                        nameTo="AntiSaccInitDelayTo"
                        v-model:from="exStore.data.fg.AntiSaccInitialDelay[0]"
                        v-model:to="exStore.data.fg.AntiSaccInitialDelay[1]"
                        :min="0"
                        :max="15"
                        :step="0.5"
                      />
                      <RangeInput
                        title="Start Delay:"
                        nameFrom="AntiSaccIntervalFrom"
                        nameTo="AntiSaccIntervalTo"
                        v-model:from="exStore.data.fg.AntiSaccInterval[0]"
                        v-model:to="exStore.data.fg.AntiSaccInterval[1]"
                        :min="0"
                        :max="15"
                        :step="0.5"
                      />
                    </div>
                    <div class="column">
                      <SequenceNumbers
                        title="Number of Saccades to each point:"
                        name="saccadeCount"
                        defaultNum="3"
                        v-model:pointsSelect="exStore.data.fg.saccadeFromCenter"
                        v-model:numbers="exStore.data.fg.saccadeCount"
                      />
                    </div>
                  </div>
                </template>

                <!-- Point To Point Animations Settings -->
                <template v-if="exStore.data.foregroundAnim == 'pointtopoint'">
                  <div class="columns">
                    <!-- from and to points -->
                    <div class="column">
                      <SelectPosition
                        title="From:"
                        name="fromPoint"
                        type="radio"
                        :gridSize="exStore.data.fg.gridSize"
                        v-model="exStore.data.fg.fromPoint"
                      />
                    </div>
                    <div class="column">
                      <SelectPosition
                        title="To:"
                        name="toPoint"
                        type="radio"
                        :gridSize="exStore.data.fg.gridSize"
                        v-model="exStore.data.fg.toPoint"
                      />
                    </div>
                  </div>
                  <!-- OPTIONS FOR POINT TO POOINT ANIMATION -->
                  <div class="columns">
                    <!-- FORWARD ANIMATION -->
                    <div class="column">
                      <Toggle
                        name="Forward Anim"
                        :title="
                          'Forward Animation (to ' +
                          exStore.data.fg.toPoint +
                          '):'
                        "
                        value1="pursuit"
                        value2="saccade"
                        v-model="exStore.data.fg.forwardAnim"
                      />
                      <AnimOptions
                        :type="exStore.data.fg.forwardAnim"
                        v-model:pursuitSpeed="
                          exStore.data.fg.forwardPursuitSpeed
                        "
                        v-model:saccadeSteps="
                          exStore.data.fg.forwardSaccadeSteps
                        "
                        v-model:saccadeType="exStore.data.fg.forwardSaccadeType"
                        v-model:saccadeInterval="
                          exStore.data.fg.forwardSaccadeInterval
                        "
                        :saccadeOptions="arrayForOptions(8, 1, '', ' saccades')"
                        :saccadeTypeOptions="exLibStore.saccadeTypeOptions"
                        :pursuitsOptions="exLibStore.pursuitSpeedOptions"
                      />
                    </div>
                    <!-- BACKWARD ANIMATION -->
                    <div class="column">
                      <Toggle
                        name="Backward Anim"
                        :title="
                          'Return Animation (to ' +
                          exStore.data.fg.fromPoint +
                          '):'
                        "
                        value1="pursuit"
                        value2="saccade"
                        v-model="exStore.data.fg.backwardAnim"
                      />
                      <AnimOptions
                        :type="exStore.data.fg.backwardAnim"
                        v-model:pursuitSpeed="
                          exStore.data.fg.backwardPursuitSpeed
                        "
                        v-model:saccadeSteps="
                          exStore.data.fg.backwardSaccadeSteps
                        "
                        v-model:saccadeType="
                          exStore.data.fg.backwardSaccadeType
                        "
                        v-model:saccadeInterval="
                          exStore.data.fg.backwardSaccadeInterval
                        "
                        :saccadeOptions="arrayForOptions(8, 1, '', ' saccades')"
                        :saccadeTypeOptions="exLibStore.saccadeTypeOptions"
                        :pursuitsOptions="exLibStore.pursuitSpeedOptions"
                      />
                    </div>
                  </div>
                  <hr />
                  <!-- PAUSES -->
                  <div class="columns">
                    <div class="column">
                      <RangeInput
                        title="Start Delay:"
                        nameFrom="saccades"
                        nameTo="randSaccPauseTo"
                        v-model:from="exStore.data.fg.initialDelay[0]"
                        v-model:to="exStore.data.fg.initialDelay[1]"
                        :min="0"
                        :max="15"
                        :step="0.5"
                      />
                      <RangeInput
                        title="Forward Anim End Pause:"
                        nameFrom="saccades"
                        nameTo="randSaccPauseTo"
                        v-model:from="exStore.data.fg.forwardEndPause[0]"
                        v-model:to="exStore.data.fg.forwardEndPause[1]"
                        :min="0"
                        :max="15"
                        :step="0.5"
                      />
                    </div>
                    <div class="column">
                      <RangeInput
                        title="Backward Anim End Pause:"
                        nameFrom="saccades"
                        nameTo="randSaccPauseTo"
                        v-model:from="exStore.data.fg.backwardEndPause[0]"
                        v-model:to="exStore.data.fg.backwardEndPause[1]"
                        :min="0"
                        :max="15"
                        :step="0.5"
                      />
                      <label class="label" for="PtoPRepeat"
                        >Repeat Anim Part:</label
                      >
                      <input
                        type="number"
                        class="input narrowinput"
                        id="PtoPRepeat"
                        name="PtoPRepeat"
                        v-model.number="exStore.data.fg.PtoPRepeat"
                        :min="1"
                        :max="20"
                        :step="1"
                      />
                    </div>
                  </div>
                  <div class="containter box has-background-light">
                    <div class="block">
                      <button
                        class="button is-success"
                        @click="addPtoPAnim(this.exStore.data)"
                      >
                        <i class="ri-add-box-fill"></i> Add Animation Part/Chunk
                      </button>
                    </div>

                    <!-- List of Animations -->
                    <table
                      v-if="exStore.data.fg.PtoPList.length > 0"
                      class="table is-striped is-hoverable is-fullwidth"
                    >
                      <thead>
                        <tr>
                          <th class="has-text-centered">
                            <abbr title="Order">#</abbr>
                          </th>
                          <th class="has-text-centered">
                            <abbr title="Points">Points</abbr>
                          </th>
                          <th class="has-text-centered">
                            <abbr title="Forward Animation Settings"
                              >Forward Anim</abbr
                            >
                          </th>
                          <th class="has-text-centered">
                            <abbr title="Backward Animation Settings"
                              >Backward Anim</abbr
                            >
                          </th>
                          <th class="has-text-centered">
                            <abbr title="Controls">Ctrls</abbr>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in exStore.data.fg.PtoPList"
                          :key="index"
                        >
                          <td>
                            <p class="is-size-5 has-text-weight-bold">
                              {{ index + 1 }}
                            </p>
                            <p>x{{ item.repeat }}</p>
                          </td>
                          <td class="is-size-7">
                            <p>
                              <span
                                class="tag is-medium is-link is-light has-text-weight-semibold"
                              >
                                {{ item.fromPt }}
                              </span>
                              ↔
                              <span
                                class="tag is-medium is-link is-light has-text-weight-semibold"
                              >
                                {{ item.toPt }}
                              </span>
                            </p>
                            <p>
                              Init-Pause:
                              <span
                                class="is-underlined has-text-weight-semibold"
                              >
                                {{ item.initialDelay[0] }}-{{
                                  item.initialDelay[1]
                                }}
                              </span>
                              secs
                            </p>
                          </td>
                          <td class="is-size-7">
                            <p>
                              <span
                                class="is-capitalized is-underlined has-text-weight-semibold"
                              >
                                {{ item.forward.type }}
                              </span>
                              <template v-if="item.forward.type == 'pursuit'">
                                Speed:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.forward.pSpeed }}
                                </span>
                              </template>
                              <template v-if="item.forward.type == 'saccade'">
                                Steps:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.forward.sSteps }}
                                </span>
                                , Type:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.forward.sType }}
                                </span>
                                , Interval:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.forward.sInt[0] }}-{{
                                    item.forward.sInt[1]
                                  }}
                                </span>
                                secs
                              </template>
                            </p>
                            <p>
                              Mid-Pause:
                              <span
                                class="is-underlined has-text-weight-semibold"
                              >
                                {{ item.forward.endPause[0] }}-{{
                                  item.forward.endPause[1]
                                }}
                              </span>
                              secs
                            </p>

                            <!-- Return Animation info -->
                          </td>
                          <td class="is-size-7">
                            <p>
                              <span
                                class="is-capitalized is-underlined has-text-weight-semibold"
                              >
                                {{ item.backward.type }}
                              </span>
                              <template v-if="item.backward.type == 'pursuit'">
                                Speed:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.backward.pSpeed }}
                                </span>
                              </template>
                              <template v-if="item.backward.type == 'saccade'">
                                Steps:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.backward.sSteps }}
                                </span>
                                , Type:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.backward.sType }}
                                </span>
                                , Interval:
                                <span
                                  class="is-underlined has-text-weight-semibold"
                                >
                                  {{ item.backward.sInt[0] }}-{{
                                    item.backward.sInt[1]
                                  }}
                                </span>
                                secs
                              </template>
                            </p>

                            <p>
                              End-Pause:
                              <span
                                class="is-underlined has-text-weight-semibold"
                              >
                                {{ item.backward.endPause[0] }}-{{
                                  item.backward.endPause[1]
                                }}
                              </span>
                              secs
                            </p>
                          </td>
                          <td class="">
                            <button
                              v-if="index > 0"
                              class="button is-small is-success"
                              @click="moveSegment(index, -1)"
                            >
                              ▲
                            </button>
                            <button
                              v-if="index < exStore.data.fg.PtoPList.length - 1"
                              class="button is-small is-success"
                              @click="moveSegment(index, 1)"
                            >
                              ▼
                            </button>
                            <button
                              class="button is-small is-danger has-text-weight-bold"
                              @click="removeSegment(index)"
                            >
                              <i class="ri-close-circle-fill ri-xl"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </div>

              <div class="title is-5">Foreground: Fixation Point</div>
              <div class="columns">
                <div
                  class="column is-align-items-baseline"
                  style="font-size: 30px"
                >
                  <i
                    ref="fix_i"
                    id="fix_i"
                    style="height: 2em; border-radius: 60%"
                    :style="{
                      color: exStore.data.fg.fixSymbolColor,
                      background: exStore.data.fg.fixBackColor,
                    }"
                    :class="[
                      exStore.data.fg.fixPointSizeSel,
                      exStore.data.fg.fixPointTypeSel,
                    ]"
                  ></i>
                </div>
                <div class="column">
                  <Options
                    name="fixPointTypeSel"
                    title="Fixation Point Type"
                    v-model="exStore.data.fg.fixPointTypeSel"
                    :array="exLibStore.fixPointTypeOptions"
                  />
                </div>
                <div class="column">
                  <Options
                    name="fixSymbolColor"
                    title="Fixation Symbol Color"
                    v-model="exStore.data.fg.fixSymbolColor"
                    :array="exLibStore.ColorOptions"
                  />
                </div>
                <div class="column">
                  <Options
                    name="fixBackColor"
                    title="Fixation Back Color"
                    v-model="exStore.data.fg.fixBackColor"
                    :array="exLibStore.ColorOptions"
                  />
                </div>
                <div class="column">
                  <Options
                    name="fixPointSizeSel"
                    title="Fixation Point Size"
                    v-model="exStore.data.fg.fixPointSizeSel"
                    :array="exLibStore.fixPointSizeOptions"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- BACKGROUND PANEL -->

          <div class="containter box has-background-grey-lighter">
            <div class="columns">
              <div class="column">
                <Options
                  name="backgroundAnim"
                  title="Background Animation Type"
                  v-model="exStore.data.backgroundAnim"
                  :array="exLibStore.backgroundAnimTypes"
                ></Options>
              </div>
              <div class="column">
                <Toggle
                  name="hemistim"
                  title="Overlay Visual Field Stim:"
                  value1="yes"
                  value2="no"
                  v-model="exStore.data.HStim"
                />
              </div>
            </div>

            <div class="title is-5">Background Animation Settings:</div>
            <!-- HEMISTIM SETTIINGS -->
            <template v-if="exStore.data.HStim === 'yes'">
              <p class="label">HemiStim Settings</p>
              <div class="columns">
                <div class="column">
                  <SelectQuadrant
                    name="hStimQuadArray"
                    v-model:inputSelect="exStore.data.hStimQuadArray"
                  />
                  <Options
                    name="HStimDelay"
                    title="HemiStim Flicker Rate:"
                    v-model="exStore.data.HStimDelay"
                    :array="exLibStore.HStimDelayOptions"
                  />
                </div>
                <div class="column">
                  <Options
                    name="HStimColor1"
                    title="Checkerboard Color 1:"
                    v-model="exStore.data.HStimColor1"
                    :array="exLibStore.ColorOptions"
                  />
                  <Options
                    name="HStimColor2"
                    title="Checkerboard Color 2:"
                    v-model="exStore.data.HStimColor2"
                    :array="exLibStore.ColorOptions"
                  />
                </div>
              </div>
            </template>

            <!-- BACKGROUND COLORS,  TEXTURES OR OPTOKINETIC -->

            <div class="columns">
              <div class="column">
                <div
                  class="block texturepot"
                  :style="
                    exStore.data.backgroundAnim == 'color' ||
                    exStore.data.HStimBGType != 'color'
                      ? { background: exStore.data.backgroundColor }
                      : ''
                  "
                >
                  <TextureAnim
                    v-if="
                      exStore.data.backgroundAnim != 'color' &&
                      exStore.data.HStimBGType != 'color'
                    "
                    :direction="exStore.data.OPKDirection"
                    :stripeWidth="exStore.data.BGSize"
                    :speed="exStore.data.OPKSpeed"
                    :backTexture="exStore.data.backTexture"
                    :stripe1Color="exStore.data.OPKStripeColor1"
                    :stripe2Color="exStore.data.OPKStripeColor2"
                    :AnimateTexture="exStore.data.AnimateTexture"
                  />
                </div>
              </div>
              <div
                class="column"
                v-if="
                  exStore.data.backgroundAnim == 'texture' ||
                  exStore.data.backgroundAnim == 'opk'
                "
              >
                <Options
                  name="backTexture"
                  title="Background Texture:"
                  v-model="exStore.data.backTexture"
                  :array="exLibStore.backTextureOptions"
                />
                <Options
                  name="BGSize"
                  title="Size:"
                  v-model="exStore.data.BGSize"
                  :array="exLibStore.BGSizeOptions"
                />

                <div v-if="exStore.data.backTexture == 'stripes'">
                  <Options
                    name="Color1"
                    title="Optokinetic Stripe Color 1:"
                    v-model="exStore.data.OPKStripeColor1"
                    :array="exLibStore.ColorOptions"
                  />
                  <Options
                    name="Color2"
                    title="Optokinetic Stripe Color 2:"
                    v-model="exStore.data.OPKStripeColor2"
                    :array="exLibStore.ColorOptions"
                  />
                </div>
              </div>

              <div class="column" v-if="exStore.data.backgroundAnim == 'opk'">
                <div class="block">
                  <Options
                    name="OPKDirection"
                    title="Optokinetic Stim Direction:"
                    v-model="exStore.data.OPKDirection"
                    :array="exLibStore.OPKDirectionOptions"
                  />
                  <Options
                    name="OPKSpeed"
                    title="Optokinetic Speed:"
                    v-model="exStore.data.OPKSpeed"
                    :array="exLibStore.OPKSpeedOptions"
                  />
                  <Toggle
                    name="animate"
                    title="Preview Animation:"
                    value1="yes"
                    value2="no"
                    v-model="exStore.data.AnimateTexture"
                  />
                </div>
              </div>
              <div
                class="column"
                v-if="
                  exStore.data.backgroundAnim == 'color' ||
                  exStore.data.HStimBGType == 'color'
                "
              >
                <Options
                  name="backgroundColor"
                  title="Background Color:"
                  v-model="exStore.data.backgroundColor"
                  :array="exLibStore.ColorOptions"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="box container has-background-grey-lighter">
          <div class="columns">
            <div class="column">
              <Toggle
                name="durationMethod"
                title="Complete Exercise By:"
                value1="Countdown"
                value2="Repetition"
                v-model="exStore.data.durationMethod"
              />
            </div>
            <div class="column">
              <Options
                v-if="exStore.data.durationMethod === 'Repetition'"
                name="repetitions"
                title="Repetitions:"
                v-model="exStore.data.repetitions"
                :array="arrayForOptions(15, 1, 'x', '')"
              />
              <Options
                v-if="exStore.data.durationMethod === 'Countdown'"
                name="duration"
                title="Countdown:"
                v-model="exStore.data.duration"
                :array="arrayForOptions(15, 5, '', ' secs')"
              />
            </div>
          </div>
        </div>

        <div class="box container has-background-grey-lighter">
          <div
            v-if="showNotify"
            class="notification is-light"
            :class="authStore.notifyClass"
          >
            {{ authStore.responseMsg }}
          </div>
          <div class="justify-content-center">
            <div class="field is-grouped">
              <ButtonComp type="save" @clickEvent="updateExercise()">
                Save/Update
              </ButtonComp>
              <ButtonComp type="play" @clickEvent="playExercise(exStore.data)">
                Preview
              </ButtonComp>
            </div>
          </div>

          <!-- <router-link tag="button" class="button is-primary" :to="{ name: 'test-play', props: { exData: this.exStore.data } }" >Test Play</router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "remixicon/fonts/remixicon.css";

// import Editor from "@tinymce/tinymce-vue";

//Pinia imports
import { useExerciseLibStore } from "@/store/ExerciseLib";
// import { useExerciseStore } from "@/store/Exercise";
import { useAuthStore } from "@/store/Auth";

import Toggle from "@/components/form_components/Toggle.vue";
import SelectQuadrant from "@/components/form_components/SelectQuadrant.vue";
import SelectPosition from "@/components/form_components/SelectPosition.vue";
import Options from "@/components/form_components/Options.vue";
import SequenceNumbers from "@/components/form_components/SequenceNumbers.vue";
import TextureAnim from "@/components/player_components/TextureAnim.vue";
import AnimOptions from "@/components/form_components/AnimOptions.vue";
import RangeInput from "@/components/form_components/RangeInput.vue";
import ButtonComp from "./dashboard_components/ButtonComp.vue";

// import TipTap from "@/components/tiptap/TipTap.vue";
import Editor from "@/components/tiptap/TipTap.vue";

export default {
  name: "EditExercise",
  setup() {
    const exLibStore = useExerciseLibStore();
    // const exStore = useExerciseStore();
    const authStore = useAuthStore();
    return { exLibStore, authStore };
  },
  components: {
    Editor,
    SequenceNumbers,
    Toggle,
    SelectQuadrant,
    SelectPosition,
    Options,
    TextureAnim,
    AnimOptions,
    RangeInput,
    ButtonComp,
  },
  props: {
    PresetEdit: Boolean,
  },
  data: () => ({
    showNotify: false,
    exStore: {
      title: "",
      instructions: "",
      data: {
        // TOP SETTINGS PANEL
        foregroundAnim: "pointtopoint",
        backgroundAnim: "color",
        //FOREGROUND SETTINGS
        fg: {
          type: "none", // to possibiliy replace foregroundAnim
          gridSize: 7,
          //FIXATION POINT SETTINGS VARS
          fixPointTypeSel: "ri-copper-coin-fill",
          fixPointSizeSel: "ri-1x",
          fixSymbolColor: "#ce3b3b",
          fixBackColor: "#000000",
          //QUADS VARS
          randSaccQuadArray: [true, false, true, false],
          randSaccNumArray: [3, 3, 3, 3],
          randSaccPauseInterval: [1, 3],
          randSaccUseIcon: "icon",
          randSaccText: "Sample",
          randSaccUseTapTarget: "auto",
          //TARGET MAP POINT INFORMATION
          //Target Selection Vars
          singlePoint: "0",
          //Point to Point
          PtoPList: [],
          fromPoint: "L2",
          toPoint: "R2",
          PtoPRepeat: 3,
          //Forward Anim Settings
          initialDelay: [1, 2],
          forwardAnim: "pursuit",
          forwardPursuitSpeed: 0.2,
          forwardSaccadeSteps: 4,
          forwardSaccadeType: "gap",
          forwardSaccadeInterval: [0.5, 2],
          forwardEndPause: [2, 3],
          //Backward Anim Settings
          backwardAnim: "saccade",
          backwardPursuitSpeed: 0.2,
          backwardSaccadeSteps: 4,
          backwardSaccadeType: "gap",
          backwardSaccadeInterval: [0.5, 2],
          backwardEndPause: [1, 2],
          //point list Anims
          fixSequencePoints: [],
          fixMethod: "Pursuit",
          fixSequenceInterval: [1.4, 4],
          //saccadeFromCenter & Anti-Saccades
          saccadeFromCenter: [],
          saccadeCount: {},
          saccadeTargetMethod: "random",
          AntiSaccInitialDelay: [1, 2],
          AntiSaccInterval: [0.5, 2],
        },
        //BACKGROUND ANIMATION PANEL VARS
        // HEMI STIM
        HStim: "no",
        HStimDelay: "200",
        hStimQuadArray: [false, false, false, false],
        HStimColor1: "#336699",
        HStimColor2: "#cecece",
        //OPK/OKN SETTINGS
        OPKDirection: "L",
        AnimateTexture: "no",
        BGSize: "400",
        OPKSpeed: "4",
        OPKStripeColor1: "#ce3b3b",
        OPKStripeColor2: "#cecece",
        backgroundType: "Solid",
        backTexture: "stripes",
        backgroundColor: "#cecece",
        //FINAL PANEL
        durationMethod: "Repetition",
        duration: 5,
        repetitions: 4,
      },
    },
  }),
  computed: {
    randSaccTextOutput() {
      // get number of spaces
      const sel = this.exStore.data.fg.randSaccQuadArray; //which quadrant is selected
      var total = this.exStore.data.fg.randSaccNumArray.reduce(
        (accumulator, next, index) => {
          next = sel[index] ? next : 0;
          return accumulator + next;
        },
        0
      );
      return this.exStore.data.fg.randSaccText.padEnd(total, "•");
    },
  },
  methods: {
    arrayForOptions: function (length, multiplier, prefix, suffix) {
      let a = [];
      for (let i = 1; i <= length; i++) {
        a.push({
          value: i * multiplier,
          name: prefix + i * multiplier + suffix,
        });
      }
      return a;
    },
    addPtoPAnim(content) {
      let d = JSON.parse(JSON.stringify(content.fg));
      let obj = {
        initialDelay: d.initialDelay,
        repeat: d.PtoPRepeat,
        fromPt: d.fromPoint,
        toPt: d.toPoint,
        forward: {
          type: d.forwardAnim,
          pSpeed: d.forwardPursuitSpeed,
          sSteps: d.forwardSaccadeSteps,
          sType: d.forwardSaccadeType,
          sInt: d.forwardSaccadeInterval,
          endPause: d.forwardEndPause,
        },
        backward: {
          type: d.backwardAnim,
          pSpeed: d.backwardPursuitSpeed,
          sSteps: d.backwardSaccadeSteps,
          sType: d.backwardSaccadeType,
          sInt: d.backwardSaccadeInterval,
          endPause: d.backwardEndPause,
        },
      };
      console.log(obj);
      this.exStore.data.fg.PtoPList.push(obj);
    },
    removeSegment(index) {
      this.exStore.data.fg.PtoPList.splice(index, 1);
    },
    moveSegment(index, d) {
      const tmp = this.exStore.data.fg.PtoPList[index];
      this.exStore.data.fg.PtoPList[index] =
        this.exStore.data.fg.PtoPList[index + d];
      this.exStore.data.fg.PtoPList[index + d] = tmp;
    },
    playExercise() {
      if (this.PresetEdit) {
        // this.authStore.presetSelection = index;
        this.authStore.selectedPreset.data = this.exStore.data;
      } else {
        this.authStore.selectedExercise.data = this.exStore.data;
      }

      this.$router.push({
        name: "player",
      });
    },
    updateExercise() {
      if (this.PresetEdit) {
        // this.authStore.selectedExercise.data = this.exStore.data;
        this.authStore.updatePreset(this.exStore);
        this.showNotify = true;
      } else {
        // this.authStore.selectedExercise = this.exStore;
        this.authStore.updateExercise(this.exStore);
        this.showNotify = true;
      }
    },
    closeExEdit() {
      if (this.PresetEdit) {
        this.$router.push({ name: "preset-list" });
      } else {
        this.$router.push({ name: "exercise-list" });
      }
    },
    updateData() {
      // Because direct replacement removes some properties of the object
      // we have used this method to update the local model, to maintain it's
      // properties but it also allows for progressive addition of features
      // breaking
      const type = this.PresetEdit ? "selectedPreset" : "selectedExercise";
      var obj = this.exStore.data;

      this.exStore.id = this.authStore[type].id;
      this.exStore.title = this.authStore[type].title;
      this.exStore.instructions = this.authStore[type].instructions;

      Object.keys(obj).forEach((key) => {
        if (key == "fg") {
          // console.log("in fg");
          Object.keys(obj["fg"]).forEach((k) => {
            // console.log(`fg -> key: ${k}, value: ${obj["fg"][k]}`);
            if (this.authStore[type].data.fg[k] != undefined) {
              this.exStore.data.fg[k] = this.authStore[type].data.fg[k];
            }
          });
          // this.iterate(obj[key]);
        } else {
          // console.log(`key: ${key}, value: ${obj[key]}`);
          if (this.authStore[type].data[key] != undefined) {
            this.exStore.data[key] = this.authStore[type].data[key];
          }
        }
      });
    },
  },
  watch: {
    // change animation completion setting
    "exStore.data.foregroundAnim"(value) {
      console.log("WATCHED");
      if (["none", "fixation"].includes(value)) {
        this.exStore.data.durationMethod = "Countdown";
      } else {
        this.exStore.data.durationMethod = "Repetition";
      }
    },
  },
  created() {
    console.log("created");
    this.updateData();
  },
  // beforeUpdate() {
  //   console.log("beforeUpdate");
  //   this.updateData();
  // },
};
</script>

<style>
/* OVERRIDE */
.is-grouped {
  justify-content: center;
}

.textinput {
  width: 95% !important;
}

.texturepot {
  border: 1px solid #555;
  border-radius: 8px;
  height: 100%;
  vertical-align: middle;
  font-size: 30px;
  overflow: hidden;
}

.fixation {
  position: absolute;
}
</style>
