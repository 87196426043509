<template>
  <div class="columns p-4">
    <div class="column is-2">
      <ButtonComp type="add" @clickEvent="addExercise()">
        Add Exercise
      </ButtonComp>
    </div>
    <div class="column">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Exercise List:</p>
          <a href="#" class="card-header-icon" aria-label="more options"> </a>
        </header>
        <div v-if="isLoaded" class="card-content">
          <div class="content">
            <table class="table is-fullwidth is-striped is-hoverable">
              <tbody>
                <tr
                  v-for="(item, index) in authStore.selectedClient
                    .prescriptions[0].exercises"
                  :key="item.id"
                >
                  <td>
                    <div class="field is-grouped has-addons is-centered">
                      <ButtonComp
                        :disabled="index < 1"
                        type="up"
                        @clickEvent="swapUp(index)"
                      >
                      </ButtonComp>
                      <ButtonComp
                        :disabled="
                          index >
                          authStore.selectedClient.prescriptions[0].exercises
                            .length -
                            2
                        "
                        type="down"
                        @clickEvent="swapDown(index)"
                      >
                      </ButtonComp>
                    </div>
                  </td>
                  <td>{{ item.list_id }}</td>
                  <td>
                    {{ item.title }}
                  </td>
                  <td class="level-right">
                    <div class="field is-grouped">
                      <ButtonComp
                        type="delete"
                        @clickEvent="confirmDelete(index)"
                      >
                        Delete
                      </ButtonComp>
                      <ButtonComp type="edit" @clickEvent="editExercise(index)">
                        Edit
                      </ButtonComp>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="card-content">
          <div class="content">No Exerises</div>
        </div>
        <!-- <footer class="card-footer">
      <a href="#" class="card-footer-item">View All</a>
    </footer> -->
      </div>
    </div>
  </div>

  <div>
    <!-- MODALS -->

    <!-- MODAL FOR ADDING EXERCISE -->
    <ModalComp
      @closeModal="closeExModal()"
      title="Add Exercise From Preset"
      :openModal="addExModal"
    >
      <ExercisePresets :PresetEdit="false" />
    </ModalComp>

    <!-- MODAL FOR DELETING EXERCISE -->
    <ModalComp
      @closeModal="closeDeleteModal()"
      title="Confirm Delete"
      :openModal="deleteModal"
    >
      <div class="container">
        <p class="is-size-4">Delete this exercise?</p>
        <article
          v-if="authStore.selectedExercise != null"
          class="message is-link"
        >
          <div class="message-body">
            <p class="is-size-5">
              <span class="has-text-weight-light">
                <i class="ri-health-book-fill is-size-3"></i>
                {{ authStore.selectedExercise.title }}</span
              >
            </p>
          </div>
        </article>

        <hr />
        <article class="message is-danger">
          <div class="message-body">
            CAN NOT BE UNDONE. ALL DATA WILL BE LOST!
          </div>
        </article>
        <div class="field is-grouped justify-content-center">
          <ButtonComp
            type="delete"
            @clickEvent="deleteExercise(authStore.selectedExercise.id)"
          >
            Delete Exercise
          </ButtonComp>
          <ButtonComp type="cancel" @clickEvent="closeDeleteModal()">
            Cancel
          </ButtonComp>
        </div>
      </div>
    </ModalComp>
  </div>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

// import VizStimSettings from "@/components/VizStimSettings.vue";
import ExercisePresets from "./ExercisePresets.vue";
import ButtonComp from "../dashboard_components/ButtonComp.vue";
import ModalComp from "../dashboard_components/ModalComp.vue";

export default {
  name: "ExerciseList",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  components: { ExercisePresets, ButtonComp, ModalComp },
  data: () => ({
    addExModal: false,
    deleteModal: false,
    exerciseID: null,
  }),
  computed: {
    isLoaded() {
      var check = false;
      if (this.authStore.selectedClient !== null) {
        if (
          this.authStore.selectedClient.prescriptions[0].exercises !==
            undefined &&
          this.authStore.selectedClient.prescriptions[0].exercises.length > 0
        ) {
          check = true;
        }
      }
      return check;
    },
  },
  methods: {
    editExercise(index) {
      this.authStore.exerciseSelection = index;
      this.$router.push({ name: "edit-exercise" });
    },
    deleteExercise(index) {
      this.authStore.deleteExercise(index);
      this.closeDeleteModal();
    },
    closeExModal() {
      this.addExModal = false;
    },
    addExercise() {
      this.addExModal = true;
    },
    confirmDelete(index) {
      this.authStore.exerciseSelection = index;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.authStore.exerciseSelection = null;
      this.deleteModal = false;
    },
    swapDown(index) {
      console.log("swap Down index", index);
      this.authStore.swapExercise(index, index + 1);
    },
    swapUp(index) {
      console.log("Swap UP index", index);
      this.authStore.swapExercise(index, index - 1);
    },
  },
  mounted() {
    // this.authStore.exerciseSelection = null;
    // this.authStore.selectedClient.prescriptions[0].exercises = ex;
  },
  //   data: () => ({

  //   }),
};
</script>

<style></style>
