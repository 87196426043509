<template>
  <router-view />
</template>

<style>
:root {
  --toggle-checked-bgcolor: #3e56c4;
  --toggle-checked-hover-bg: #46d;
  --toggle-checked-txtcolor: #eee;
  --toggle-unchecked-color: #bbb;
  --toggle-unchecked-hover-bg: #ccc;
  --toggle-border-color: #888;
  --toggle-disabled-bg: #888;
  --toggle-disabled-txt: #444;
  --toggle-disabled-border: #666;
  /* --toggle-txt-color: #333; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
