<template>
  <section class="info-tiles">
    <div class="tile is-ancestor has-text-centered">
      <div
        v-for="item in authStore.prescriptOptions"
        :key="item.order"
        class="tile is-parent"
      >
        <article
          @click="createPrescription(item)"
          class="tile is-primary is-child box"
        >
          <p class="title is-4">{{ item.title }}</p>
          <p class="subtitle">{{ item.subtitle }}</p>
          <!-- <button class="button is-primary">Add</button> -->
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { useAuthStore } from "@/store/Auth";

export default {
  name: "PresetSelect",
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  methods: {
    async createPrescription(p) {
      p.client = this.authStore.selectedClient.id;
      this.authStore.createPrescription(p);
    },
  },
};
</script>

<style></style>
