<template>
  <p class="control">
    <button
      class="button is-rounded"
      :class="typeObj.class"
      :disabled="disabled"
      @click="$emit('clickEvent')"
    >
      <span class="icon">
        <i :class="typeObj.icon"></i>
      </span>
      <span>
        <slot></slot>
      </span>
    </button>
  </p>
</template>

<script>
export default {
  name: "ButtonComp",
  props: {
    type: String,
    modelValue: String,
    disabled: Boolean,
  },
  emits: ["clickEvent"],
  data: () => ({
    typeData: [
      { name: "delete", class: "is-danger", icon: "ri-delete-bin-2-line" },
      { name: "edit", class: "is-success", icon: "ri-edit-2-line" },
      { name: "cancel", class: "", icon: "ri-close-line" },
      { name: "save", class: "is-primary", icon: "ri-save-3-fill" },
      { name: "add", class: "is-success", icon: "ri-add-circle-fill" },
      { name: "play", class: "is-info", icon: "ri-play-circle-line" },
      { name: "up", class: "is-info", icon: "ri-arrow-up-fill" },
      { name: "down", class: "is-info", icon: "ri-arrow-down-fill" },
    ],
  }),
  computed: {
    typeObj() {
      return this.typeData.find((o) => o.name === this.type);
    },
  },
};
</script>
