<template>
  <div class="block toggle">
    <p class="label">Quadrants:</p>
    <div class="columns">
      <input
        v-if="quadAmount != undefined"
        type="number"
        class="input is-small narrowinput"
        v-model="quadAmount[0]"
        :disabled="!selection[0]"
      />
      <input type="checkbox" :id="name + '.TL.value'" v-model="selection[0]" />
      <label :for="name + '.TL.value'" style="border-radius: 7px 0 0 0"
        >Up Left</label
      >

      <input
        type="checkbox"
        :id="name + '.TR.value'"
        :name="name + '.TR.value'"
        v-model="selection[1]"
      />
      <label :for="name + '.TR.value'" style="border-radius: 0 7px 0 0"
        >Up Right</label
      >
      <input
        v-if="inputAmount != undefined"
        type="number"
        class="input is-small narrowinput"
        :name="name + '.TR.amount'"
        v-model="quadAmount[1]"
        :disabled="!selection[1]"
      />
    </div>
    <div class="columns is-vcentered">
      <input
        v-if="inputAmount != undefined"
        type="number"
        class="input is-small narrowinput"
        :name="name + '.BL.quadAmount'"
        v-model="quadAmount[2]"
        :disabled="!selection[2]"
      />
      <input
        type="checkbox"
        :id="name + '.BL.value'"
        :name="name + '.BL.value'"
        v-model="selection[2]"
      /><label :for="name + '.BL.value'" style="border-radius: 0 0 0 7px"
        >Down Left</label
      >

      <input
        type="checkbox"
        :id="name + '.BR.value'"
        :name="name + '.Br.value'"
        v-model="selection[3]"
      />
      <label :for="name + '.BR.value'" style="border-radius: 0 0 7px 0"
        >Down Right
      </label>
      <input
        v-if="inputAmount != undefined"
        type="number"
        class="input is-small narrowinput"
        :name="name + '.BR.quadAmount'"
        v-model="quadAmount[3]"
        :disabled="!selection[3]"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormQuads",
  props: ["inputSelect", "inputAmount", "name"],
  emits: ["update:inputSelect"],
  computed: {
    selection: {
      get() {
        return this.inputSelect;
      },
      set(v) {
        this.$emit("update:inputSelect", v);
      },
    },
    quadAmount: {
      get() {
        return this.inputAmount;
      },
      set(v) {
        this.$emit("update:inputAmount", v);
      },
    },
  },
  data() {
    return {
      //   name: "name",
      //   selection: {
      //     TL: { checked: true, amount: 3 },
      //     TR: { checked: false, amount: 3 },
      //     BL: { checked: true, amount: 3 },
      //     BR: { checked: false, amount: 3 },
      //   },
    };
  },
};
</script>

<style scoped>
.toggle {
  margin-left: auto;
  margin-right: auto;
  max-width: 14em;
}

.narrowinput {
  width: 4.5em;
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle label {
  display: flex;
  width: 50%;
  min-width: 6em;
  border: 1px solid var(--toggle-border-color);
  height: 2.4em;
  background: var(--toggle-unchecked-color);
  align-items: center;
  justify-content: center;
}

.toggle label:hover {
  background: var(--toggle-unchecked-hover-bg);
  cursor: pointer;
}

.toggle input[type="checkbox"]:checked + label {
  color: var(--toggle-checked-txtcolor);
  background: var(--toggle-checked-bgcolor);
  font-weight: 600;
}

.toggle input[type="checkbox"]:checked + label:hover {
  background: var(--toggle-checked-hover-bg);
}

.toggle input[type="checkbox"]:disabled + label {
  color: var(--toggle-disabled-bg);
  font-weight: 400;
  background: var(--toggle-disabled-txt);
  cursor: auto;
}

.toggle input[type="checkbox"]:checked:disabled + label {
  background: #aaa;
}
</style>
