import { defineStore } from "pinia";
// import { router } from "@/router";
import axios from "axios";

export const useAuthStore = defineStore({
  id: "authStore",
  state: () => ({
    user: null,
    isAuthenticated: false,
    clientList: null,
    presetList: [],
    presetSelection: null,
    clientSelection: null,
    exerciseSelection: null,
    responseMsg: "",
    notifyClass: "is-warning",
    // exDataTemp: null,
    prescriptOptions: [
      {
        order: 1,
        title: "Add Prescription",
        subtitle: "Basic format",
        client: null,
        description:
          "<h3>Your Recipe:</h3> <p> This is prescript option 1 </p>",
        expiry_date: null,
        exercises: [],
      },
      {
        order: 2,
        title: "Add list",
        subtitle: "this is the other reason to pick this",
        client: null,
        description:
          "<h3>Your Recipe:</h3> <p> This is prescript option 2 </p>",
        expiry_date: null,
        exercises: [],
      },
      {
        order: 3,
        title: "Long Format",
        subtitle: "Long format with no date",
        client: null,
        description:
          "<h3>Your Recipe:</h3> <ul> My list: <li>Item 1</li> </ul> <p> This is prescript option 3 </p>",
        expiry_date: null,
        exercises: [],
      },
    ],
  }),
  getters: {
    selectedPreset(state) {
      if (state.presetSelection !== null) {
        return state.presetList[state.presetSelection];
      } else {
        return null;
      }
    },
    selectedClient(state) {
      if (state.clientSelection !== null) {
        return state.clientList[state.clientSelection];
      } else if (state.user.role == "CLIENT") {
        return state.user;
      } else {
        return null;
      }
    },
    selectedExercise(state) {
      if (["PROVIDER", "ADMIN"].includes(this.user.role)) {
        if (
          this.selectedClient !== null &&
          this.selectedClient.prescriptions !== undefined &&
          this.selectedClient.prescriptions.length > 0
        ) {
          return this.selectedClient.prescriptions[0].exercises[
            state.exerciseSelection
          ];
        } else {
          return null;
        }
      } else {
        return this.user.prescriptions[0].exercises[state.exerciseSelection];
      }
    },
  },
  actions: {
    errorParse(error) {
      console.log(error.config);
      this.notifyClass = "is-danger";
      if (error.response) {
        console.log("The request was made and the server responded with error");
        console.log(error.response);
        this.responseMsg =
          "Server responded with error: " + error.response.data.detail;
        return error.response;
      } else if (error.request) {
        console.log("The request was made but no response was recieved");
        console.log(error.request);
        this.responseMsg = "The request was made but no response was recieved";
        return error.request;
      } else {
        this.responseMsg = "Error. Try again or contact Administrator.";
        console.log("Error. Try again or contact Administrator.");
        return null;
      }
    },
    responseParse(response) {
      if (response.status == 200) {
        this.responseMsg = "Update Succesful!";
        this.notifyClass = "is-success";
      } else {
        this.responseMsg = "Cannot confirm update as Successful. Please check";
        this.notifyClass = "is-warning";
      }
    },
    // for sorting user exercises not presets
    sortExArray(array) {
      return array.sort((a, b) => {
        return a.list_id - b.list_id;
      });
    },
    // AUTH CALLS
    async setCSRF() {
      try {
        const response = await axios.get("set-csrf/");
        console.log("Set CSRF step:");
        console.log(response);
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async logIn(User) {
      try {
        const response = await axios.post("login/", User);
        await this.checkUser(); // do I want to return any info from this?
        this.responseParse(response);
        return true;
      } catch (error) {
        return false;
      }
    },
    async logOut() {
      try {
        const response = await axios.post("logout/");
        this.checkUser();
        return response.data;
      } catch (error) {
        return this.errorParse(error);
      }
    },
    // USER CALLS
    async checkUser() {
      try {
        const response = await axios.get("currentuser/");
        // this.availabilityMessage = response.data.message;
        console.log("check user SUCCESS!!!!");
        this.user = response.data;
        this.isAuthenticated = true;

        if (this.user.role == "CLIENT") {
          this.sortExArray(this.user.prescriptions[0].exercises);
        }
        // console.log("clientList", this.clientList);
        // this.clientList.forEach(
        //   (obj) => {
        //     console.log(obj);
        //   }
        //   // this.sortExArray(obj.prescriptions[0].exercises)
        // );

        return "Success";
      } catch (error) {
        this.user = null;
        this.isAuthenticated = false;
        return this.errorParse(error);
      }
    },
    async getClientList() {
      try {
        const response = await axios.get("clientlist/");
        this.clientList = response.data;
        this.clientList.forEach((obj) => {
          this.sortExArray(obj.prescriptions[0].exercises);
        });
        return response.data; //needed?
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async updateUser(User) {
      try {
        const response = await axios.patch("users/" + User.id + "/", User);
        this.responseParse(response);
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async deleteClient() {
      try {
        const response = await axios.delete(
          "users/" + this.selectedClient.id + "/",
          this.selectedClient
        );
        this.responseParse(response);
        this.getClientList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async createUser(User) {
      try {
        const response = await axios.post("createuser/", User);
        this.responseParse(response);
        this.getClientList();
      } catch (error) {
        return this.errorParse(error);
      }
    },

    // PRESET CALLS
    async getPresetList() {
      try {
        const response = await axios.get("presetlist/");
        response.data.sort((a, b) => {
          return a.order - b.order;
        });
        this.presetList = response.data;
        return response.data; //needed?
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async createPreset(e) {
      try {
        const response = await axios.post("createpreset/", e);
        this.responseParse(response);
        this.getPresetList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async updatePreset(e) {
      try {
        const response = await axios.patch("preset/" + e.id + "/", e);
        this.responseParse(response);
        this.getPresetList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async swapPresets(index1, index2) {
      const p1 = this.presetList[index1];
      const p2 = this.presetList[index2];
      const p_above = {
        id: p1.id,
        order: p2.order,
      };
      const p_below = {
        id: p2.id,
        order: p1.order,
      };
      try {
        const response1 = await axios.patch(
          "preset/" + p_above.id + "/",
          p_above
        );
        const response2 = await axios.patch(
          "preset/" + p_below.id + "/",
          p_below
        );
        this.responseParse(response1);
        this.responseParse(response2);
        this.getPresetList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async deletePreset(id) {
      try {
        const response = await axios.delete("preset/" + id + "/");
        this.responseParse(response);
        this.getPresetList();
      } catch (error) {
        return this.errorParse(error);
      }
    },

    // PRESCRIPTION CALLS
    async createPrescription(p) {
      try {
        const response = await axios.post("createprescription/", p);
        this.responseParse(response);
        this.getClientList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async updatePrescription(p) {
      try {
        const response = await axios.patch("updateprescript/" + p.id + "/", p);
        this.responseParse(response);
      } catch (error) {
        this.errorParse(error);
      }
    },
    // EXERCISE CALLS
    async getUserExerciseList() {
      console.log("get list!!");
      try {
        const response = await axios.get(
          "users/" + this.selectedClient.id + "/"
        );
        this.responseParse(response);
        if (this.user.role == "CLIENT") {
          var a = response.data.prescriptions[0].exercises;
          console.log("aaray", a);
          this.user.prescriptions[0].exercises = a;
          this.user.prescriptions[0].exercises.sort((a, b) => {
            return a.list_id - b.list_id;
          });
        } else {
          this.selectedClient.prescriptions[0].exercises =
            response.data.prescriptions[0].exercises.sort((a, b) => {
              return a.list_id - b.list_id;
            });
        }
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async createExercise(e) {
      try {
        const response = await axios.post("createexercise/", e);
        this.responseParse(response);
        this.getUserExerciseList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async updateExercise(e) {
      try {
        const response = await axios.patch("updateexercise/" + e.id + "/", e);
        this.responseParse(response);
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async deleteExercise(e) {
      try {
        const response = await axios.delete("updateexercise/" + e + "/");
        this.responseParse(response);
        this.getUserExerciseList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
    async swapExercise(index1, index2) {
      console.log("swapExercise: index1", index1, "index2:", index2);
      const e1 = this.selectedClient.prescriptions[0].exercises[index1];
      const e2 = this.selectedClient.prescriptions[0].exercises[index2];
      console.log("e1", e1, "e2", e2);
      const e_above = {
        id: e1.id,
        list_id: e2.list_id,
      };
      const e_below = {
        id: e2.id,
        list_id: e1.list_id,
      };
      try {
        const response1 = await axios.patch(
          "updateexercise/" + e_above.id + "/",
          e_above
        );
        const response2 = await axios.patch(
          "updateexercise/" + e_below.id + "/",
          e_below
        );
        this.responseParse(response1);
        this.responseParse(response2);
        // this.selectedClient.prescriptions[0].exercises[index1] = response1.data;
        // this.selectedClient.prescriptions[0].exercises[index2] = response2.data;
        this.getUserExerciseList();
      } catch (error) {
        return this.errorParse(error);
      }
    },
  },
});
