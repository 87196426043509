<template>
  <div style="min-width: 6em">
    <p class="label">{{ title }}:</p>
    <div
      v-for="(item, index) in pointsArray"
      :key="index"
      class="saccadeNumbers"
    >
      <div>
        <button
          v-if="index != 0"
          class="button is-small is-link"
          @click.prevent="swapEntries($event, item, 'left')"
        >
          <i class="ri-arrow-left-fill ri-xl"></i>
        </button>
        <label class="has-text-weight-bold" :for="name + item"
          >{{ item }}
        </label>
        <button
          v-if="index != pointsArray.length - 1"
          class="button is-small is-link"
          @click.prevent="swapEntries($event, item, 'right')"
        >
          <i class="ri-arrow-right-fill ri-xl"></i>
        </button>
      </div>
      <input
        v-if="numbers != undefined"
        class="input is-small"
        type="number"
        v-model="myObj[item]"
        :id="name + item"
        :name="name + '.' + item"
        min="1"
        max="20"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormEditSequence",
  props: {
    pointsSelect: Array,
    title: String,
    name: String,
    defaultNum: String,
    numbers: Object,
  },
  emits: ["update:pointSelect"],
  computed: {
    pointsArray: {
      get() {
        return this.pointsSelect;
      },
      set(v) {
        this.$emit("update:pointSelect", v);
      },
    },
    myObj: {
      get() {
        return this.numbers;
      },
      set(newVal) {
        this.value = newVal;
      },
    },
  },
  // watch: {
  //   // whenever question changes, this function will run
  //   pointsArray() {
  //     this.syncPointsAndNums();
  //   },
  // },
  methods: {
    swapEntries: function (event, message, dir) {
      if (event) {
        let index = this.pointsArray.indexOf(message);
        console.log(dir, index);
        if (dir == "left") {
          let rows = [this.pointsArray[index - 1], this.pointsArray[index]];
          this.pointsArray.splice(index - 1, 2, rows[1], rows[0]);
        } else {
          let rows = [this.pointsArray[index], this.pointsArray[index + 1]];
          this.pointsArray.splice(index, 2, rows[1], rows[0]);
        }
      }
    },

    syncPointsAndNums: function () {
      //Fill selected points with default number if there is none
      this.pointsSelect.forEach((element) => {
        if (!Object.prototype.hasOwnProperty.call(this.myObj, element)) {
          this.myObj[element] = Number(this.defaultNum);
        }
      });
      //Remove obj entries that no longer match pointsSelect
      for (let e in this.myObj) {
        if (!this.pointsSelect.includes(e)) {
          delete this.myObj[e];
          //   this.$delete(this.myObj, e);
        }
      }
    },
  },
  created: function () {
    if (this.numbers != undefined) {
      this.syncPointsAndNums();
    }
  },
  beforeUpdate: function () {
    if (this.numbers != undefined) {
      this.syncPointsAndNums();
    }
  },
};
</script>

<style scoped>
.saccadeNumbers {
  display: inline-block;
  /* width: 6em; */
  margin-left: 4px;
  margin-bottom: 4px;
  background: #bbb;
  border-radius: 5px;
  padding: 3px 5px;
  border: 1px solid #777;
}
.saccadeNumbers .button {
  padding: 0;
  height: 1.8em;
}
.saccadeNumbers label {
  padding: 0px 7px;
}
.saccadeNumbers input {
  width: 4.3em;
}

label {
  margin: 0px;
}
.btn {
  padding: 0px 4px;
  /* background: #579; */
  margin-left: 2px;
  margin-right: 2px;
}
.btn:hover {
  background: #79b;
}
</style>
