<template>
  <div class="grid box">
    <p class="label">{{ title }} {{ selection }}</p>
    <div v-for="row in gridSize" :key="row">
      <template v-for="item in gridSize" :key="item">
        <label v-if="!isDisabled(item, row)" class="radio">
          <input
            :type="type"
            :id="label(item, row)"
            :value="label(item, row)"
            :disabled="disable == label(item, row) ? true : false"
            v-model="selection"
          />
        </label>
        <div v-else class="space"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "type", "modelValue", "gridSize", "disable"],
  emits: ["update:modelValue"],
  computed: {
    selection: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    midPoint() {
      return Math.ceil(this.gridSize / 2);
    },
    rowLabel() {
      let a = [];
      for (let i = 1; i <= this.gridSize; i++) {
        if (i < this.midPoint) {
          a.push("U");
        } else if (i > this.midPoint) {
          a.push("D");
        } else {
          a.push("");
        }
      }
      return a;
    },
    itemLabel() {
      let a = [];
      for (let i = 1; i <= this.gridSize; i++) {
        if (i < this.midPoint) {
          a.push("L");
        } else if (i > this.midPoint) {
          a.push("R");
        } else {
          a.push("");
        }
      }
      return a;
    },
  },
  methods: {
    isDisabled(row, item) {
      return item - (this.gridSize - row) != 1 &&
        row - item != 0 &&
        row != this.midPoint &&
        item != this.midPoint
        ? true
        : false;
    },
    label(item, row) {
      return (
        this.rowLabel[row - 1] +
        this.itemLabel[item - 1] +
        (row === this.midPoint
          ? Math.abs(item - this.midPoint)
          : Math.abs(row - this.midPoint))
      );
    },
  },
};
</script>

<style>
.change {
  visibility: hidden;
}
.space {
  width: 1.6em;
  display: inline-block;
}
/* .normal{
  appearance: ;
} */
.grid {
  margin: 0em 2em;
  background: #eee;
  min-width: v-bind((gridSize * 2-1) + "em");
}
</style>
